import React, { useState } from 'react'
import { NAVBAR } from './navbar/navbar'
import { SLIDE_AWS } from './slide_aws/slide_aws'
import "./microsite.css";
import { CERTIFICATES } from './certificates/certificates';
import { SERVICES } from './services/services';
import { FOOTERAWS } from './footer/footer';

export const MICROSITE_AWS = () => {

  console.log(localStorage.getItem("lenguaje_hi"))


  const [ingles, setingles]=useState(  
      localStorage.getItem("lenguaje_hi") === null
        ? false
        : localStorage.getItem("lenguaje_hi") === "true"
        ? true
        : false
  );
  


  return (
    <div className='container-microsite'>
        <NAVBAR ingles={ingles} setingles={setingles}></NAVBAR>
        <SLIDE_AWS ingles={ingles}></SLIDE_AWS>
        <SERVICES ingles={ingles}></SERVICES>
        <CERTIFICATES ingles={ingles}></CERTIFICATES>
        <FOOTERAWS ingles={ingles}></FOOTERAWS>
    </div>
  )
}
