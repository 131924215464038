import React from 'react';
import "./tarjeta.css";
import fto from "../img-components/foto-ivan.jpeg";
import logo from "../img-components/logos/logo-hi.png";
import c1 from "../img-components/cert1.png";
import c2 from "../img-components/cert3.png";


export const TARJETA = () => {
  return (
    <div className='tarjeta-div'>
        <div className='slider-columns-tarjeta'>
           </div>
        <div className='foto'>
            <img src={fto} alt=''></img>
        </div>
        <div className='title'>
            <p>MSC. IVÁN AGUILAR ENRÍQUEZ</p>
        </div>
        <div className='p-text'>
            <p>CEO Herramientas Informáticas</p>
        </div>
        <div className='columns'>
            <div className='certificaciones'>
                <h2>Certificaciones:</h2>
                <img src={c1}></img>
                <img src={c2}></img>
            </div>
            <div className='info-ivan'>
                <img src={logo} alt='logo' className='logo-hi' onClick={()=>window.location="www.herramientasinformaticas.com"}></img>
                <h2>HERRAMIENTAS INFORMÁTICAS</h2>
                <p><span onClick={()=>window.location="www.herramientasinformaticas.com"}>www.herramientasinformaticas.com</span></p>
            </div>
        </div>
        
    </div>
  )
}
