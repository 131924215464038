import React, { useRef, useState } from 'react'
import "./contact.css";
import hi from "../../img-components/logos/logo-hi.png";
import RoomIcon from '@mui/icons-material/Room';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import axios from "axios";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ReCAPTCHA from "react-google-recaptcha";

export const CONTACT = (props) => {

    const captcha = useRef(null);
    const [name, setname]=useState("");
    const [email, setemail]=useState("");
    const [message, setmessage]=useState("");
    const [phone, setphone]=useState("");
 

    const verificar = () =>{
        if(captcha.current.getValue()){
            console.log("El usuario no es un robot");
        }
    }

    const verificarcorreo =(email)=>{
        const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regexCorreo.test(email);
    }

    const enviarCorreo = ()=> {
        
        if (captcha.current.getValue()) {

            if(name==="" || name?.trim()=="" || email === "" || email?.trim()==="" || message === ""){
                alert("Complete los campos del formulario");
                return null;
            }           
            const d = verificarcorreo(email);
            if(d===false){
                alert("Correo no válido");
                return null;
            }
            if(phone==="" || phone?.trim()===""){
                alert("Completa todos los campos del formulario");
                return null;
            }

            axios.post('https://uygo0kqh45.execute-api.us-east-1.amazonaws.com/Productive/contact',
                { 
                    type: `contactar`, 
                    name: name, 
                    message: message, 
                    email: email,
                    phone: phone
                }
            )
            .then(function (res) {
                console.log(res)
                setname("");
                setemail("");
                setmessage("");
                setphone("");
               
            })
            .catch(function (err) {
                console.log(err);
            });
        }else{
            alert("Completa el CAPTCHA antes de enviar el formulario.");
            return;
        }
    }

  return (
    <div className='contact-container' id='contact'>
        <div className='contact-div'>
            <div className='contac-div-form'>
                <div className='capa-oscura-contact'> 
                </div>
                <div className='form-contac'>
                    <h2 className='contact-title'>{props.ingles===true?"Contact us":"Contactanos"}</h2>
                    
                    <div className='input-name'>
                        <input className='input-text' value={name} placeholder={props.ingles===true?"Name":'Nombre'} onChange={(e)=>setname(e.target.value)}></input>
                    </div>
                    <div className='input-email'>
                        <input className='input-text' value={email} placeholder={props.ingles===true?"Email":'Correo'} onChange={(e)=>setemail(e.target.value)}></input>
                    </div>
                    <div className='input-phone'>
                        <input className='input-text' value={phone} placeholder={props.ingles===true?"Phone Number":'Número Teléfono'} onChange={(e)=>setphone(e.target.value)}></input>
                    </div>
                    <div className='input-msg'>
                        <textarea className='input-textarea' value={message} placeholder={props.ingles===true?'Message':"Mensaje"} onChange={(e)=>setmessage(e.target.value)}></textarea>
                    </div>
                    <div className='input-captcha'>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey="6LfUNm4oAAAAAGXAGIJh5DYP1sVjIOKJepXJwe4b"
                            onChange={verificar}
                        />
                    </div>
                    <div className='button-enviar'>
                        <button onClick={()=>enviarCorreo()}>{props.ingles===true?"SUBMIT":"ENVIAR"}</button>
                    </div>
                </div>
            </div>
            
            <div className='address'>
                <div className='container-adrees'>
                    <div className='logo-empresa'>
                        <img src={hi}></img>
                    </div>
                    <div className='info-hi'>
                        <div className='higrid1'>
                            <div className='grid-a'>
                                <RoomIcon className='icon'></RoomIcon>
                            </div>
                            <div className='grid-b'>
                                <p className='p1'>{props.ingles===true?"Address":"Ubicación"} </p>
                                <p className='p2'>Independencia 900, Barrio Nuevo, 94340, Orizaba, Ver. México</p>
                            </div>
                        </div>
                         <div className='higrid1'>
                            <div className='grid-a'>
                                <AttachEmailIcon className='icon'></AttachEmailIcon>
                            </div>
                            <div className='grid-b'>
                                <p className='p1'>{props.ingles===true?"Email":"Correo"} </p>
                                <p className='p2'>atencion.clientes@herramientasinformaticas.com</p>
                            </div> 
                        </div>
                        <div className='higrid1'>
                            <div className='grid-a'>
                                <LocalPhoneIcon className='icon'></LocalPhoneIcon>
                            </div>
                            <div className='grid-b'>
                                <p className='p1'>{props.ingles===false?"Número telefónico":"Phone number"} </p>
                                <p className='p2'>+52 272 723 0084 </p>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className='container-map'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.837744165043!2d-97.07484350317755!3d18.849880999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c4fd47be6857fd%3A0xc49e3eb8ce25d623!2sIndependencia%20900%2C%20Barrio%20Nuevo%2C%2094340%20Orizaba%2C%20Ver.!5e0!3m2!1sen!2smx!4v1671207415540!5m2!1sen!2smx"
                        width="100%" height="100%" frameborder="0">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
  )
}
