import React from 'react'
import "./saas.css";
import fondo from "../../img-components/saas-transformed.jpeg";
import { SAAS_SECTION } from '../../microsite-custom/saas/saas';

export const SASS = (props) => {
  return (
    <>
    <div className='saas-container'>
        <div className='seccion-saas'>
            <div className='info-sass'>
                <h2>SOFTWARE AS A SERVICE</h2>
                <h3>S A A S</h3>
                {props?.ingles===true?
                  <p>We offer models that can be used in any organization to simplify work processes.</p>:
                  <p>Contamos con módulos que pueden ser usados en cualquier organización para simplificar el trabajo</p>
                }
            </div>
        </div>
    </div>
    <SAAS_SECTION ingles={props?.ingles}></SAAS_SECTION>
    </>
    
  )
}
