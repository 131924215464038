import React from 'react'
import "./services.css";


export const SERVICES = (props) => {
  return (
    <>
    <div className='Services-container' id='solutions-aws-secc'>
        <div className='services-solutions'>
            <h2>{props.ingles===true?"AWS Solutions":"Soluciones AWS"}</h2>
            {
                props.ingles===true?
                <>
                <p>
                    At Herramientas Informáticas, we not only manage IT resources on the AWS platform and facilitate the migration 
                    of systems and information to the cloud, but we also implement advanced solutions based on AWS technologies like 
                    Lambda, API Gateway, and DynamoDB. 
                </p>
                <p>
                    A prominent example of our expertise is the migration and operation of systems like SAP in the AWS cloud for our 
                    clients. Moreover, with our solutions based on Lambda and other services, we optimize and adapt operations to the 
                    cloud environment, ensuring efficiency and scalability. Contact us for more details on how we can support your 
                    transition and management in the cloud, as well as in the implementation of specific AWS-based solutions.
                </p>
                </>:
                <>
                <p>
                    En Herramientas Informáticas, no solo gestionamos los recursos de IT en la plataforma AWS y
                    facilitamos la migración de sistemas e información hacia la nube, sino que también implementamos 
                    soluciones avanzadas basadas en tecnologías de AWS como Lambda, API Gateway y DynamoDB. 
                </p>
                <p>
                    Un ejemplo destacado de nuestra experiencia es la migración y ejecución de sistemas como 
                    SAP en la nube de AWS para nuestros clientes. Además, con nuestras soluciones basadas en
                    Lambda y otros servicios, optimizamos y adaptamos las operaciones al entorno en la nube, 
                    garantizando eficiencia y escalabilidad. Contáctanos para más detalles sobre cómo podemos 
                    apoyarte en tu transición y gestión en la nube, así como en la implementación de soluciones 
                    específicas basadas en AWS
                </p>
                </>
            }
            
        </div>
    </div>
    <div className='services-items'>
        <div className='capa'></div>
        <div className='grid-items'>
            <div className='si1'>
                <p className='title'>{props.ingles===true?"Security":"Seguridad"}</p>
                {
                    props.ingles===true?
                    <p>
                        We protect your data with AWS solutions, using User Pool ID, IAM, and Amazon 
                        Cognito for secure and authenticated access.
                    </p>:
                    <p>
                        Protegemos tus datos con soluciones AWS, utilizando User Pool ID, 
                        IAM y Amazon Cognito para un acceso seguro y autenticado.
                    </p>
                }
                    
            </div>
            <div className='si2'>
                <p className='title'>{props.ingles===true?"Storage":"Almacenamiento"}</p>
                {
                    props.ingles===true?
                    <p>
                        We utilize the combination of AWS Lambda, EC2, and Amazon S3 for secure, scalable, and efficient storage.
                    </p>:
                    <p>
                        Utilizamos la combinación de AWS Lambda, EC2 y Amazon S3 para un almacenamiento seguro, escalable y eficiente.
                    </p>
                }
                    
            </div>
            <div className='si3'>
                <p className='title'>{props.ingles===true?"Migration":"Migración"}</p>
                {
                    props.ingles===true?
                    <p>
                        We facilitate the transition of your systems to the AWS cloud, ensuring a seamless migration.
                    </p>:
                    <p>
                        Facilitamos la transición de tus sistemas a la nube de AWS, garantizando una migración sin interrupciones.
                    </p>
                }
                    
            </div>
            <div className='si4'>
                <p className='title'>{props.ingles===true?"Backup":"Respaldo"}</p>
                {
                    props.ingles===true?
                    <p>
                        Your data is backed up in the AWS cloud, ensuring its integrity and availability at all times.
                    </p>:
                    <p>
                       Tus datos están respaldados en la nube AWS, asegurando su integridad y disponibilidad en todo momento.
                    </p>

                }
                    
                
            </div>
        </div>
        
    </div>
    </>
    
  )
}
