import React from 'react';
import "./footer.css";
import hi from "../../img-components/logos/logo-hi.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import {CustomDialog} from "react-st-modal";
import { PRIVACIDAD } from './privacidad';

export const FOOTER = (props) => {

  const handlepriv = (item)=>{
    CustomDialog(               
      <div className=''>
         <PRIVACIDAD ingles={props.ingles} item={item}></PRIVACIDAD>
      </div>,
    {
      className: "modal-text",
      title: "",
      showCloseIcon: true,
      isCanClose:false,
    }
    )
  }

  

  return (
    <div className='footer-container'>
      <div className='footer-seccions'>
        <div className='logo'>
          <img src={hi} alt=''></img>
          <h2>HERRAMIENTAS INFORMÁTICAS</h2>
        </div>
        <div className='secc1'>
          <h2>{props.ingles===true?"Services":"Servicios"}</h2>
          <div className='line-2'></div>
          {
            props.ingles===true?
            <>
              <p>Cloud</p>
              <p>Consultancy</p>
              <p>Training</p>
              <p>Technical Support</p>
            </>:
            <>
              <p>Nube</p>
              <p>Consultoria</p>
              <p>Capacitación</p>
              <p>Soporte Técnico</p>
            </>
          }
          
        </div>
        <div className='secc2'>
          <h2>{props.ingles===true?"Partners":"Socios"}</h2>
          <div className='line-2'></div>
          <p onClick={()=>window.location="#aws"}>Amazon Web Services</p>
          <p onClick={()=>window.location="#seccion-google-container"}>Google</p>
        </div>
        <div className='secc3'>
          <h2>{props.ingles===true?"About Us":"Nosotros"}</h2>
          <div className='line-2'></div>
          {
            props.ingles===true?
            <>
              <p onClick={()=>window.location="#about"}>Vision</p>
              <p onClick={()=>window.location="#about"}>Mission</p>
              <p onClick={()=>window.location="#valores-seccion"}>Values</p>
            </>:
            <>
              <p onClick={()=>window.location="#about"}>Visión</p>
              <p onClick={()=>window.location="#about"}>Misión</p>
              <p onClick={()=>window.location="#valores-seccion"}>Valores</p>
            </>
          }
          
        </div>
        <div className='secc4'>
          <h2>{props.ingles===true?"Contact":"Contacto Directo"}</h2>
          <div className='line-2'></div>
          <div className='redes whatsapp' 
            onClick={()=>{
              window.open("https://api.whatsapp.com/send?phone=5212727230084", "_blank");
            }
          }
          >
            <WhatsAppIcon 
            ></WhatsAppIcon>
            <p>Whatsapp</p>
          </div>
          <div className='redes gmail' onClick={()=>{
            window.location="/#contact";
          }}>
            <EmailIcon></EmailIcon>
            <p>{props.ingles===true?"Email":"Correo Electrónico"}</p>
          </div>
        </div>
      </div>
      <div className='copy'>
        <p>Copyright © 2023 {props.ingles===true?"All rights reserved":"Todos los derechos reservados"} | @herramientasinformaticas &nbsp;&nbsp;&nbsp; | { props.ingles===false?<span className='privacidad' onClick={()=>handlepriv(1)}>Aviso de privacidad</span>:<></>} &nbsp;&nbsp;&nbsp;| {props.ingles===true?<></>:<span className='privacidad' onClick={()=>handlepriv(2)}>Términos de uso</span>}</p>
      </div>
    </div>
  )
}
