import React, { useEffect, useState } from 'react'
import logohi from "../../../img-components/logos/logo-hi.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "./navbar.css";
import MenuIcon from '@mui/icons-material/Menu';

export const NAVBAR = (props) => {

    const[menu, setmenu]=useState(false);
    const[menuing, setmenuing]=useState(false);

    useEffect(() => {
        
        if (menu === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible'; // O 'auto' si prefieres restaurar el scroll
        }
        return () => {
            document.body.style.overflow = 'visible'; // O 'auto' si es necesario
        };
    }, [menu])
    


  return (
    
    <div className='navbar-container-aws'>
    <div className='grid-menu-aws'>
        <div className='icono-aws'>
            <img src={logohi} onClick={()=>window.location="/"}></img>
        </div>
        <div className='menu-items-aws'>
            <ul className='ul-items-aws'>
                <li><a href="/" data-section="#" id="item2">{props.ingles===true?"Home":"Inicio"}</a></li>
                 <li>
                    <div class="language-dropdown-aws">
                        {props?.ingles===true?"Language":"Idioma"} <ArrowDropDownIcon></ArrowDropDownIcon>
                        <ul class="language-options-aws">
                            {
                                props.ingles===true?
                                <li onClick={
                                    ()=>{
                                        props.setingles(false)
                                        localStorage.setItem("lenguaje_hi", "false");
                                    }}><a href="#">Español</a></li>:
                                <li onClick={
                                    ()=>{
                                        props.setingles(true)
                                        localStorage.setItem("lenguaje_hi", "true");
                                    }
                                }><a href="#">Inglés</a></li>
                            }                 
                        </ul>
                    </div>
                </li>
                
            </ul>
           
        </div>
        <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
        {
            menu===true?    
                <div className='menu-flotante-cel'>
                    <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
                    <ul className='menu-flotante-items'>
                        <li><a href='/' onClick={()=>setmenu(false)} data-section="#aws-img1" id='item3'>{props.ingles===true?"Home":"Inicio"}</a></li>
                        <li onClick={()=>setmenuing(!menuing)}>{props.ingles===true?"Language":"Idioma"} <ArrowDropDownIcon></ArrowDropDownIcon>
                       {
                        menuing===true?
                            <>
                                {
                                    props.ingles===true?
                                    <li className="cambiaridioma" onClick={
                                        ()=>{
                                            props.setingles(false)
                                            localStorage.setItem("lenguaje_hi", "false");
                                        }}><a href="#">Español</a></li>:
                                    <li className="cambiaridioma" onClick={
                                        ()=>{
                                            props.setingles(true)
                                            localStorage.setItem("lenguaje_hi", "true");
                                        }
                                    }><a href="#">Inglés</a></li>
                                }                 
                            </>:
                        <></>
                       }
                     </li>
                    
                    </ul>
                </div>:
                <></>
        }
        
    </div>
    
</div>
  )
}
