import React, { useEffect, useRef, useState } from 'react';
import "./solutions.css";
import s1 from "../../img-components/icono/solution1.png";
import s2 from "../../img-components/icono/solution2.png";
import s3 from "../../img-components/icono/solution3.png";
import s4 from "../../img-components/icono/solution4.png";

import img1 from "../../img-components/servicio3.png";
import img2 from "../../img-components/servicio2.png";
import img3 from "../../img-components/servicio4.png";
import img4 from "../../img-components/servicio5.png";


import logo1 from "../../img-components/logos/bol.png";
import logo2 from "../../img-components/logos/ht.png";
import logo3 from "../../img-components/logos/iaia.png";
import logo4 from "../../img-components/logos/uni.png";
import logo5 from "../../img-components/logos/enecen.png";
import logo6 from "../../img-components/logos/fricongelados.png";
import logo7 from "../../img-components/logos/curiel.png";
import logo8 from "../../img-components/logos/kele.png";

import { ERP } from './erp/erp';




export const SOLUTIONS = (props) => {


  const[item1, setitem1]=useState(true);
  const[item2, setitem2]=useState(false);
  const[item3, setitem3]=useState(false);
  const[item4, setitem4]=useState(false);
  
  const sectionRef = useRef(null);

  const handleitem=(item)=>{
      if(item===1){
        setitem1(true);
        setitem2(false);
        setitem3(false);
        setitem4(false);
      }else if(item===2){
        setitem1(false);
        setitem2(true);
        setitem3(false);
        setitem4(false);
      }else if(item===3){
        setitem1(false);
        setitem2(false);
        setitem3(true);
        setitem4(false);
      }else if(item===4){
        setitem1(false);
        setitem2(false);
        setitem3(false);
        setitem4(true);
      }
  }

  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          document.getElementById("clien1").classList.add("animate__fadeInUp");
          document.getElementById("clien2").classList.add("animate__fadeInUp");
          document.getElementById("clien3").classList.add("animate__fadeInUp");
        } else {
          document.getElementById("clien1").classList.remove("animate__fadeInUp");
          document.getElementById("clien2").classList.remove("animate__fadeInUp");
          document.getElementById("clien3").classList.remove("animate__fadeInUp");
          
        }
      },
      { 
        root: null,
        threshold: 0.1,
      }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);


  return (
    <div className='solutions_container' ref={sectionRef}>
        {/* <ERP ingles={props.ingles}></ERP> */}
        <div className='about-clients-confi' id='client'>
                  <div className='capa-dark-ser'></div>
                  <h3 id="clients" class="clientConf">{props.ingles===true?"CUSTOMERS WHO TRUST US:":"Clientes que confian en nosotros:"}</h3>
                    
                    <div class="wrapper">
                      <div className='carrusel'>
                          <div class="item">
                              <img class="imgGenaral logoboll" alt="" src={logo1} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo2} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoiaia" alt="" src={logo3} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo4}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo5}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo6} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logo-curiel" alt="" src={logo7} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logo-kele" alt="" src={logo8} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoboll" alt="" src={logo1} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo2} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoiaia" alt="" src={logo3} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo4}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo5}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo6} />
                          </div>  
                      </div>  
                    </div>
        </div>
        <div className='solutions_clients'>
          {
            props.ingles===true?
            <h2 className='animate__animated animate__fadeIn' id='clien1'><span>Solutions</span> we have provided to clients include:</h2>:
            <h2 className='animate__animated animate__fadeIn' id='clien1'>¿Qué tipo de <span>soluciones</span> hemos entregado a nuestros clientes?</h2>
          }
          <div className='menu-seccion animate__animated animate__fadeIn' id='clien3'>
            <div className={item1===true?"items itemss1 itemselect":"items itemss1"} onClick={()=>handleitem(1)}>
              <img src={s1} alt=''></img>
              <p>{props.ingles===true?"Managed Cloud Services":"Servicios Administrados en nube"}</p>
            </div>
            <div className={item2===true?"items itemss2 itemselect":"items itemss2"} onClick={()=>handleitem(2)}>
              <img src={s2} alt=''></img>
              <p>{props.ingles===true?"Customized Solutions":"Soluciones Personalizadas"}</p>
            </div>
            <div className={item3===true?"items itemss3 itemselect":"items itemss3"} onClick={()=>handleitem(3)}>
              <img src={s3} alt=''></img>
              <p>{props.ingles===true?"Success Stories":"Casos de Éxito"}</p>
            </div>
            <div className={item4===true?"items itemss4 itemselect":"items itemss4"} onClick={()=>handleitem(4)}>
              <img src={s4} alt=''></img>
              <p>{props.ingles===true?"Smart Solutions":"Soluciones inteligentes"}</p>
            </div>
          </div>
          <div className='desc-items animate__animated animate__fadeIn' id='clien2'>
            {
              item1===true?
              <div className='desc-serv-items'>
                {
                  props.ingles===true?
                  <p>
                    Our clients have trusted us to migrate their "On Premise" infrastructures to the cloud, such as infrastructures where 
                    SAP execution is required, which has allowed them to work with many benefits both technical and economic compared to their 
                    previous operation.
                  </p>:
                  <p>
                    Nuestros clientes han confiado en nosotros para migrar sus infraestructuras en la empresa (on premise) a nube, como por ejemplo infraestructuras donde 
                    se requiere ejecutar SAP lo que les ha permitido trabajar con muchos beneficios tanto como técnicos como económicos en comparación
                     con su operación anterior.
                  </p>

                }
                
                <img src={img1}></img>
              </div>:
              item2===true?
              <div className='desc-serv-items'>
                {
                  props.ingles===true?
                  <p>
                    We have developed solutions that streamline and automate people's work. For example, custom business intelligence solutions, 
                    using mobile applications and making use of artificial intelligence.
                  </p>:
                  <p>Hemos desarrollado distintas soluciones que agilizan y automatizan el trabajo de las personas. Como por ejemplo soluciones a 
                    medida de inteligencia de negocios, utilizando aplicaciones  móviles y haciendo uso de la Inteligencia artificial.</p>
                }
                
                <img src={img2}></img>
              </div>:
              item3===true?
              <div className='desc-serv-items'>
                {
                  props.ingles===true?
                  <p>
                    We delivered solutions for remote work through cloud workspaces which allowed our clients' employees to work remotely, 
                    ensuring operations continued during pandemic.
                  </p>:
                  <p>Entregamos soluciones para trabajo remoto a través de espacios de trabajo en la nube (Work Space, es un PC completa y lista para utilizarse desde distintos dispositivos y ubicaciones), 
                  lo que ha permitido que los colaboradores de nuestros clientes trabajen remotamente, lo cual permitió
                  que no pararan operaciones durante pandemia.</p>
                }
                
                  <img src={img3} className='im3'></img>
              </div>:
              item4===true?
              <div className='desc-serv-items'>
                {
                  props.ingles===true?
                  <p>
                    We have developed custom tools using artificial intelligence technologies, allowing our 
                    clients to automate complex processes.
                  </p>:
                  <p>
                    Hemos desarrollado una serie de herramientas personalizadas que hacen uso de tecnologías de inteligencia artificial, 
                    permitiéndole a nuestros clientes automatizar procesos complejos.
                  </p>
                }
                
                <img src={img4} className='im4'></img>
              </div>:<></>
            }            
          </div>
        </div>
    </div>
  )
}
