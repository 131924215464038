import React from 'react'
import "./carrusel.css";

export const CARRUSEL_AWS = (props) => {
  return (
    <div className='container-carrusel'>
        <div className='carrusel-aws'>
            <div className='carr1 cartaws'>
                <h2>API Gateway</h2>
                {
                    props.ingles===true?
                <p>
                    A solution that acts as an intermediary between users and our services, allowing the management, 
                    monitoring, and securing of API traffic. This portal facilitates the creation, publication, and 
                    maintenance of programming interfaces, ensuring smooth and secure communication between different
                     software and applications.
                </p>:
                <p>Una solución que actúa como intermediario entre los usuarios y nuestros servicios, permitiendo 
                    gestionar, monitorizar y asegurar el tráfico de las APIs. Este portal facilita la creación, 
                    publicación y mantenimiento de interfaces de programación, garantizando una comunicación fluida 
                    y segura entre diferentes software y aplicaciones.</p>
                }
            </div>
            <div className='carr2 cartaws'>
                <h2>DynamoDB</h2>
                {
                    props.ingles===true?
                    <p>
                        A NoSQL database service that offers millisecond performance at any scale. It's a fully managed database, 
                        with support for both document and key-value data structures. Perfect for applications that require 
                        high availability, scalability, and consistent performance.
                    </p>:
                    <p>
                        Un servicio de base de datos NoSQL que ofrece rendimiento en milisegundos a cualquier escala. 
                        Es una base de datos completamente administrada, con soporte para estructuras de datos tanto 
                        documentales como de valor clave. Perfecto para aplicaciones que necesitan alta disponibilidad, 
                        escalabilidad y rendimiento constante.
                    </p>

                }
                
            </div>
            <div className='carr3 cartaws'>
                <h2>{props.ingles===true?"EC2 Instances":"Instancias EC2"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Amazon EC2 instances provide scalable computing capacity in the cloud. They allow running applications 
                        and services on virtual machines configured according to the project's needs, offering flexibility and 
                        control over computing infrastructure.
                    </p>:
                    <p>
                        Las instancias EC2 de Amazon proporcionan capacidad informática escalable en la nube. Permiten 
                        ejecutar aplicaciones y servicios en máquinas virtuales configuradas según las necesidades del
                        proyecto, ofreciendo flexibilidad y control sobre la infraestructura de cómputo.
                    </p>

                }
                
            </div>
            <div className='carr4 cartaws'>
                <h2>SAP</h2>
                {
                    props.ingles===true?
                    <p>
                        A comprehensive suite of business applications that cover everything from manufacturing to sales. With 
                        our experience in SAP, we help companies optimize their processes and make data-driven decisions
                         in real-time, driving efficiency and growth.
                    </p>:
                    <p>
                        Una suite integral de aplicaciones empresariales que cubre todo, desde la manufactura hasta las ventas. 
                        Con nuestra experiencia en SAP, ayudamos a las empresas a optimizar sus procesos y a tomar decisiones 
                        basadas en datos en tiempo real, impulsando la eficiencia y el crecimiento.
                    </p>
                }
            </div>
            <div className='carr5 cartaws'>
                <h2>SQL Server</h2>
                {
                    props.ingles===true?
                    <p>
                        A high-performance relational database management platform. It offers advanced tools for data storage, 
                        retrieval, analysis, and protection. Our team has extensive experience in its implementation, ensuring 
                        robust and reliable data management.

                    </p>:
                    <p>
                        Una plataforma de gestión de bases de datos relacionales de alto rendimiento. Ofrece herramientas avanzadas 
                        para el almacenamiento, recuperación, análisis y protección de datos. Nuestro equipo cuenta con amplia experiencia 
                        en su implementación, asegurando una gestión de datos robusta y confiable.
                    </p>
                }            
            </div>
            <div className='carr6 cartaws'>
                <h2>{props.ingles===true?"Mobile Development":"Desarrollo Móvil"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Specialists in creating intuitive and scalable mobile applications. Whether for Android, iOS, 
                        or hybrid platforms, we design solutions that perfectly adapt to the end user's needs, ensuring 
                        a flawless user experience.
                    </p>:
                    <p>
                        Especialistas en la creación de aplicaciones móviles intuitivas y escalables. Ya sea para Android, iOS o plataformas
                        híbridas, diseñamos soluciones que se adaptan perfectamente a las necesidades del usuario final, garantizando una 
                        experiencia de usuario impecable.
                    </p>
                }
            </div>
            <div className='carr7 cartaws'>
                <h2>{props.ingles===true?"Web Development":"Desarrollo Web"}</h2>
                {
                    props.ingles===true?
                    <p>
                        We offer custom web solutions, from corporate sites to advanced web applications. Our team combines
                        the best design and development practices to ensure fast, secure, and adaptive sites for any device.
                    </p>:
                    <p>
                        Ofrecemos soluciones web a medida, desde sitios corporativos hasta aplicaciones web avanzadas. Nuestro equipo combina 
                        las mejores prácticas de diseño y desarrollo para garantizar sitios rápidos, seguros y adaptativos a cualquier dispositivo.
                    </p>
                }
            </div>
            <div className='carr8 cartaws'>
                <h2>{props.ingles===true?"Migration":"Migración"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Our team specializes in ensuring safe, efficient migrations with minimal disruptions, ensuring that
                        the transition is as smooth as possible and that the company's digital assets remain intact and 
                        available.
                    </p>:
                    <p>
                        Nuestro equipo está especializado en garantizar migraciones seguras, eficientes y con mínimas interrupciones, asegurando que la transición
                        sea lo más fluida posible y que los activos digitales de la empresa se mantengan íntegros y disponibles.
                    </p>
                }
            </div>
            <div className='carr9 cartaws'>
                <h2>{props.ingles===true?"Cloud Computing":"Computación en la Nube"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Knowledge in platforms like AWS, Google Cloud, and Azure, offering scalable and flexible cloud-based solutions.
                    </p>:
                    <p>
                        Conocimientos en plataformas como AWS, Google Cloud y Azure, ofreciendo soluciones escalables y flexibles basadas en la nube.
                    </p>
                }
            </div>
            <div className='carr10 cartaws'>
                <h2>{props.ingles===true?"Artificial Intelligence":"Inteligencia Artificial"}</h2>
                {
                    props.ingles===true?
                    <p>Development of AI-based solutions to optimize processes, predict trends, and improve decision-making.</p>
                    :<p>Desarrollo de soluciones basadas en IA para optimizar procesos, predecir tendencias y mejorar la toma de decisiones.</p>
                }
                
            </div>
            <div className='carr11 cartaws'>
                <h2>{props.ingles===true?"Project Management":"Gestión de Proyectos"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Use of methodologies like Scrum and Agile to effectively and efficiently manage IT projects.
                    </p>:
                    <p>
                        Uso de metodologías como Scrum y Agile para gestionar proyectos de TI de manera efectiva y eficiente.
                    </p>
                }
                
            </div>
            <div className='carr1 cartaws'>
                <h2>API Gateway</h2>
                {
                    props.ingles===true?
                <p>
                    A solution that acts as an intermediary between users and our services, allowing the management, 
                    monitoring, and securing of API traffic. This portal facilitates the creation, publication, and 
                    maintenance of programming interfaces, ensuring smooth and secure communication between different
                     software and applications.
                </p>:
                <p>Una solución que actúa como intermediario entre los usuarios y nuestros servicios, permitiendo 
                    gestionar, monitorizar y asegurar el tráfico de las APIs. Este portal facilita la creación, 
                    publicación y mantenimiento de interfaces de programación, garantizando una comunicación fluida 
                    y segura entre diferentes software y aplicaciones.</p>
                }
            </div>
            <div className='carr2 cartaws'>
                <h2>DynamoDB</h2>
                {
                    props.ingles===true?
                    <p>
                        A NoSQL database service that offers millisecond performance at any scale. It's a fully managed database, 
                        with support for both document and key-value data structures. Perfect for applications that require 
                        high availability, scalability, and consistent performance.
                    </p>:
                    <p>
                        Un servicio de base de datos NoSQL que ofrece rendimiento en milisegundos a cualquier escala. 
                        Es una base de datos completamente administrada, con soporte para estructuras de datos tanto 
                        documentales como de valor clave. Perfecto para aplicaciones que necesitan alta disponibilidad, 
                        escalabilidad y rendimiento constante.
                    </p>

                }
                
            </div>
            <div className='carr3 cartaws'>
                <h2>{props.ingles===true?"EC2 Instances":"Instancias EC2"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Amazon EC2 instances provide scalable computing capacity in the cloud. They allow running applications 
                        and services on virtual machines configured according to the project's needs, offering flexibility and 
                        control over computing infrastructure.
                    </p>:
                    <p>
                        Las instancias EC2 de Amazon proporcionan capacidad informática escalable en la nube. Permiten 
                        ejecutar aplicaciones y servicios en máquinas virtuales configuradas según las necesidades del
                        proyecto, ofreciendo flexibilidad y control sobre la infraestructura de cómputo.
                    </p>

                }
                
            </div>
            <div className='carr4 cartaws'>
                <h2>SAP</h2>
                {
                    props.ingles===true?
                    <p>
                        A comprehensive suite of business applications that cover everything from manufacturing to sales. With 
                        our experience in SAP, we help companies optimize their processes and make data-driven decisions
                         in real-time, driving efficiency and growth.
                    </p>:
                    <p>
                        Una suite integral de aplicaciones empresariales que cubre todo, desde la manufactura hasta las ventas. 
                        Con nuestra experiencia en SAP, ayudamos a las empresas a optimizar sus procesos y a tomar decisiones 
                        basadas en datos en tiempo real, impulsando la eficiencia y el crecimiento.
                    </p>
                }
            </div>
            <div className='carr5 cartaws'>
                <h2>SQL Server</h2>
                {
                    props.ingles===true?
                    <p>
                        A high-performance relational database management platform. It offers advanced tools for data storage, 
                        retrieval, analysis, and protection. Our team has extensive experience in its implementation, ensuring 
                        robust and reliable data management.

                    </p>:
                    <p>
                        Una plataforma de gestión de bases de datos relacionales de alto rendimiento. Ofrece herramientas avanzadas 
                        para el almacenamiento, recuperación, análisis y protección de datos. Nuestro equipo cuenta con amplia experiencia 
                        en su implementación, asegurando una gestión de datos robusta y confiable.
                    </p>
                }            
            </div>
            <div className='carr6 cartaws'>
                <h2>{props.ingles===true?"Mobile Development":"Desarrollo Móvil"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Specialists in creating intuitive and scalable mobile applications. Whether for Android, iOS, 
                        or hybrid platforms, we design solutions that perfectly adapt to the end user's needs, ensuring 
                        a flawless user experience.
                    </p>:
                    <p>
                        Especialistas en la creación de aplicaciones móviles intuitivas y escalables. Ya sea para Android, iOS o plataformas
                        híbridas, diseñamos soluciones que se adaptan perfectamente a las necesidades del usuario final, garantizando una 
                        experiencia de usuario impecable.
                    </p>
                }
            </div>
            <div className='carr7 cartaws'>
                <h2>{props.ingles===true?"Web Development":"Desarrollo Web"}</h2>
                {
                    props.ingles===true?
                    <p>
                        We offer custom web solutions, from corporate sites to advanced web applications. Our team combines
                        the best design and development practices to ensure fast, secure, and adaptive sites for any device.
                    </p>:
                    <p>
                        Ofrecemos soluciones web a medida, desde sitios corporativos hasta aplicaciones web avanzadas. Nuestro equipo combina 
                        las mejores prácticas de diseño y desarrollo para garantizar sitios rápidos, seguros y adaptativos a cualquier dispositivo.
                    </p>
                }
            </div>
            <div className='carr8 cartaws'>
                <h2>{props.ingles===true?"Migration":"Migración"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Our team specializes in ensuring safe, efficient migrations with minimal disruptions, ensuring that
                        the transition is as smooth as possible and that the company's digital assets remain intact and 
                        available.
                    </p>:
                    <p>
                        Nuestro equipo está especializado en garantizar migraciones seguras, eficientes y con mínimas interrupciones, asegurando que la transición
                        sea lo más fluida posible y que los activos digitales de la empresa se mantengan íntegros y disponibles.
                    </p>
                }
            </div>
            <div className='carr9 cartaws'>
                <h2>{props.ingles===true?"Cloud Computing":"Computación en la Nube"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Knowledge in platforms like AWS, Google Cloud, and Azure, offering scalable and flexible cloud-based solutions.
                    </p>:
                    <p>
                        Conocimientos en plataformas como AWS, Google Cloud y Azure, ofreciendo soluciones escalables y flexibles basadas en la nube.
                    </p>
                }
            </div>
            <div className='carr10 cartaws'>
                <h2>{props.ingles===true?"Artificial Intelligence":"Inteligencia Artificial"}</h2>
                {
                    props.ingles===true?
                    <p>Development of AI-based solutions to optimize processes, predict trends, and improve decision-making.</p>
                    :<p>Desarrollo de soluciones basadas en IA para optimizar procesos, predecir tendencias y mejorar la toma de decisiones.</p>
                }
                
            </div>
            <div className='carr11 cartaws'>
                <h2>{props.ingles===true?"Project Management":"Gestión de Proyectos"}</h2>
                {
                    props.ingles===true?
                    <p>
                        Use of methodologies like Scrum and Agile to effectively and efficiently manage IT projects.
                    </p>:
                    <p>
                        Uso de metodologías como Scrum y Agile para gestionar proyectos de TI de manera efectiva y eficiente.
                    </p>
                }   
            </div>
            
            

        </div>
    </div>
  )
}
