import React from 'react';
import "./petition.css";
import img1 from "../../../img-components/rombo5.png";

export const PETITIONS_MC = (props) => {
  return (
    <div className='petition-container'>
        <div className='petition-secction'>
            <div className='pett-grid'>
                <div className='pettg1'>
                    <h2>{props?.ingles === true ? "ORDER MANAGEMENT MODULE":"MÓDULO GESTIÓN PEDIDOS"}</h2>
                    {
                        props?.ingles === true ?
                        <p>
                            Using artificial intelligence, PDF documents from various sources and formats are entered 
                            into the system. The system extracts information using artificial intelligence tools, 
                            processes it to construct sales orders, which are then entered into SAP through our software.
                        </p>:
                        <p>
                            Mediante Inteligencia Artificial se ingresan documentos PDS con diferentes orígenes y
                            formatos. El sistema extrae la información mediante herramientas de inteligencia artificial, y la procesa
                            para construir ordenes de venta que se ingresan posteriormente a SAP mediante nuestro Software.
                        </p>
                    }
                    
                </div>
                <div className='pettg2'>
                    <img src={img1}></img>
                </div>
            </div>
        </div>  
    </div>
  )
}
