import React, { useState } from 'react';
import "./servicescloud.css";
import imgaws from "../../img-components/aws-partner.png";
import icono1 from "../../img-components/icono/adop1.png";
import icono2 from "../../img-components/icono/adop2.png";
import icono3 from "../../img-components/icono/adop3.png";

import iconos1 from "../../img-components/icono/soluadop1.png";
import iconos2 from "../../img-components/icono/soluadop2.png";
import iconos3 from "../../img-components/icono/soluadop3.png";
import { NAVBAR2 } from './navbar/navbar';





export const SERVICESADOP = () => {

  const [ingles, setingles]=useState(  
    localStorage.getItem("lenguaje_hi") === null
      ? false
      : localStorage.getItem("lenguaje_hi") === "true"
      ? true
      : false
);



  return (
    <>
    <NAVBAR2 ingles={ingles} setingles={setingles}></NAVBAR2>
    <div className='services-cloud-container'>
      <div className='services-cloud-title'>
        <div className='capa'></div>
        <h2>{ingles==true?"Cloud Adoption":"ADOPCIÓN DE LA NUBE"}</h2>
        {
          ingles===true?
          <p className='adv-h2'>
            With Herramientas Informáticas, cloud adoption transcends a mere migration; it's a transformative 
            journey ushering in a realm of innovation and opportunities.
          </p>:
          <p className='adv-h2'>
            Con Herramientas Informáticas, la adopción de la nube es más que una migración;
            es una transformación que abre puertas a un mundo de innovación y oportunidades.
          </p>
        }
        
      </div>
      <div className='services-cloud-body'>
        <h2>
          {ingles===true?"Elevate your business with the power of the cloud.":"Transforma tu negocio elevándolo a la nube."}
        </h2>
        {
          ingles===true?
          <p>
            With Herramientas Informáticas, unlock endless possibilities by adopting cloud-centric solutions, 
            streamlining operations, and realizing your full potential.
          </p>:
          <p>
            Con Herramientas Informáticas, descubre un mundo de posibilidades al adoptar soluciones basadas 
            en la nube, optimizando operaciones y maximizando tu potencial.
          </p>
        }

      </div>
      <div className='services-cloud-columns'>
        <div className='col1cloud gridcloud'>
          <img src={icono1} alt=''></img>
          <h2>{ingles===true?"Embracing the Cloud with Herramientas Informáticas":"Adopción de la Nube con Herramientas Informáticas"}</h2>
          {
            ingles===true?
            <p>
              In today's digital shift, transitioning to the cloud isn't a luxury; it's essential. 
              With Herramientas Informáticas, undergo a seamless, secure, and strategic shift to cloud solutions.
            </p>:
            <p>
              En un mundo que se mueve hacia la digitalización, migrar a la nube no es una opción,
              es una necesidad. Con Herramientas Informáticas, obtén una transición suave, segura 
              y estratégica hacia soluciones en la nube.
            </p>
          }
          
        </div>
        <div className='col2cloud gridcloud'>
        <img src={icono2} alt=''></img>
          <h2>{ingles===true?"Lift Your Operations, Not Just Your Data":"Eleva tus Operaciones, No solo tus Datos"}</h2>
          {
            ingles===true?
            <p>
              Beyond a mere data shift, revolutionize your operational methodology, 
              thinking, and decision-making in a digitized setting.
            </p>:
            <p>
              Más allá de simplemente mover tus datos, transforma cómo operas, piensas y tomas 
              decisiones en un entorno digitalizado.
            </p>
          }
          
        </div>
        <div className='col3cloud gridcloud'>
        <img src={icono3} alt=''></img>
          <h2>{ingles===true?"The Future Resides in the Cloud":"El Futuro es en la Nube"}</h2>
          {
            ingles===true?
            <p>
              85% of businesses plan to transition their operations to the cloud in the upcoming years. Don't 
              lag behind. With Herramientas Informáticas, guarantee an efficient and effective cloud-centric transformation.
            </p>:
            <p>
              El 85% de las empresas planean mover sus operaciones a la nube en los próximos años. 
              No te quedes atrás. Con Herramientas Informáticas, asegura una adopción eficiente y 
              efectiva de soluciones basadas en la nube.
            </p>
          }
          
        </div>
      </div>
      <div className='services-cloud-soluciones-adop' id='services-cloud-soluciones'>
        <div className='capa'></div>
        <h2>{ingles===true?"Custom Solutions Tailored for Your Business":"Soluciones Personalizadas para tu Negocio"}</h2>
        <div className='linea-title-adop'></div>
        <div className='services-sol-grid-adop'>
          <div className='solgrid1'>
            <img src={iconos1} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Strategic Migrations":"Migraciones Estratégicas"}</h2>
            {
              ingles===true?
              <p>
                With Herramientas Informáticas, strategize and execute migrations in alignment with
                 your business goals, eliminating needless expenditures and ensuring maximum ROI.
              </p>:
              <p>
                Con Herramientas Informáticas, planifica y ejecuta migraciones que se alineen con 
                tus objetivos comerciales, evitando costos innecesarios y maximizando el ROI.
              </p>
            }

          </div>
          <div className='solgrid2'>
          <img src={iconos2} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Security and Reliability":"Seguridad y Confiabilidad"}</h2>
            {
              ingles===true?
              <p>
                Security remains our paramount concern. We assure the protection of your data and cloud operations, bestowing peace of mind.
              </p>:
              <p>
                La seguridad es nuestra prioridad. Garantizamos la protección de tus datos y operaciones en la nube, brindándote tranquilidad.
              </p>
            }
          </div>
          <div className='solgrid3'>
          <img src={iconos3} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Ongoing Optimization":"Optimización Constante"}</h2>
            {
              ingles===true?
              <p>
                Adopting the cloud is merely the beginning. Continually refine and adapt your cloud 
                solutions as your business scales and evolves.
              </p>:
              <p>La seguridad es nuestra prioridad. Garantizamos la protección de tus datos y operaciones en 
                la nube, brindándote tranquilidad.</p>
        
            }
            </div>
        </div>
      </div>
    </div>
    </>
    
  )
}
