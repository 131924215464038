import React from 'react';
import "./inventory.css";
import img1 from "../../../img-components/rombo3.png";
import img2 from "../../../img-components/inventory-icons.png";
import img3 from "../../../img-components/inventory ing.png";

export const INVENTORY = (props) => {
  return (
    <div className='inventory-container'>
        <div className='inventory-seccion'>
            <div className='invg1'>
                <img src={img1}></img>
            </div>
            <div className='invg2'>
                <div className='inv-info-pag'>
                    <h2>{props?.ingles === true ? "INVENTORY":"INVENTARIO"}</h2>
                    {props?.ingles===true?
                        <p>
                            This module is designed to manage the tracking, identification, and quantification of 
                            goods traded, offering comprehensive monitoring from their entry to their delivery/sale. 
                            This allows our users to check the inventory status at any time.
                        </p>:
                        <p>
                            Módulo diseñado para llevar el rastreo, identificación y cuantificación 
                            de los bienes que se comercialicen por medio de un completo <span>seguimiento </span> 
                            desde su ingreso hasta su entrega/venta permitiendo que nuestros usuarios observen 
                            en cualquier momento el estatus del inventario.
                        </p>
                    }
                    
                </div>
                <div className='iconos-pinv'> 
                {
                    props?.ingles === true?
                    <img src={img3}></img>:
                    <img src={img2}></img>
                }
                </div>
            </div>
           
        </div>
    </div>
  )
}
