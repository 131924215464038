import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from 'react';
// css
import "./navbar.css";
//imagenes
import logohi from "../../img-components/logos/logo-hi.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const NAVBAR = (props) => {

    const[menu, setmenu]=useState(false);
    const[menuing, setmenuing]=useState(false);

    useEffect(() => {
        
        if (menu === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible'; // O 'auto' si prefieres restaurar el scroll
        }
        return () => {
            document.body.style.overflow = 'visible'; // O 'auto' si es necesario
        };
    }, [menu]);



  return (
    <div className='navbar-container'>
        <div className='grid-menu'>
            <div className='icono'>
                <img src={logohi} onClick={()=>{window.location="/"}}></img>
            </div>
            <div className='menu-items'>
                <ul className='ul-items'>
                    <li><a href="#about" data-section="#about" id="item2">{props.ingles===true?"About Us":"Nosotros"}</a></li>
                    <li><a href='#services' data-section="#services" id='item3'>{props.ingles===true?"Our Services":"Servicios"}</a></li>
                    <li><a href='#custom-container' data-section="#custom-container" id='item4'>{props.ingles===true?"Solutions":"Soluciones"}</a></li>
                    <li><a href='#client' data-section="#client" id='item5'>{props.ingles===true?"Customers":"Clientes"}</a></li>
                    <li><a href='#aws' data-section="#aws" id='item6'>{props.ingles===true?"Partners":"Socios"}</a></li>
                    <li><a href='#contact' data-section="#contact" id='item7'>{props.ingles===true?"Contact Us":"Contacto"}</a></li>
                    <li>
                        <div class="language-dropdown">
                            {props.ingles===true?"Language":"Idioma"} <ArrowDropDownIcon></ArrowDropDownIcon>
                            <ul class="language-options">
                                {
                                    props.ingles===true?
                                    <li onClick={
                                        ()=>{
                                            props.setingles(false)
                                            localStorage.setItem("lenguaje_hi", "false");
                                        }
                                    }><a href="#">Español</a></li>:
                                    <li onClick={
                                        ()=>{
                                            props.setingles(true)
                                            localStorage.setItem("lenguaje_hi", "true");
                                        }
                                    }><a href="#">Inglés</a></li>
                                }                 
                            </ul>
                        </div>
                    </li>
                    
                </ul>
            </div>
            <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
        {
            menu===true?    
                <div className='menu-flotante-cel-hi'>
                    <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
                        <ul className='menu-flotante-items'>
                        <li><a href="#about" data-section="#about" onClick={()=>setmenu(false)} id="item2">{props.ingles===true?"About Us":"Nosotros"}</a></li>
                        <li><a href='#services' data-section="#services" onClick={()=>setmenu(false)} id='item3'>{props.ingles===true?"Our Services":"Servicios"}</a></li>
                        <li><a href='#solutions' data-section="#solutions" onClick={()=>setmenu(false)} id='item4'>{props.ingles===true?"Solutions":"Soluciones"}</a></li>
                        <li><a href='#client' data-section="#client" onClick={()=>setmenu(false)} id='item5'>{props.ingles===true?"Customers":"Clientes"}</a></li>
                        <li><a href='#aws' data-section="#aws" onClick={()=>setmenu(false)} id='item6'>{props.ingles===true?"Partners":"Socios"}</a></li>
                        <li><a href='#contact' data-section="#contact" onClick={()=>setmenu(false)} id='item7'>{props.ingles===true?"Contact Us":"Contacto"}</a></li> 
                        <li onClick={()=>setmenuing(!menuing)}>{props.ingles===true?"Language":"Idioma"} <ArrowDropDownIcon></ArrowDropDownIcon>
                       {
                        menuing===true?
                            <>
                                {
                                    props.ingles===true?
                                    <li className="cambiaridioma" onClick={
                                        ()=>{
                                            props.setingles(false)
                                            localStorage.setItem("lenguaje_hi", "false");
                                        }}><a href="#">Español</a></li>:
                                    <li className="cambiaridioma" onClick={
                                        ()=>{
                                            props.setingles(true)
                                            localStorage.setItem("lenguaje_hi", "true");
                                        }
                                    }><a href="#">Inglés</a></li>
                                }                 
                            </>:
                        <></>
                       }
                     </li>
                    
                    </ul>
                </div>:
                <></>
        }
        </div>
        
    </div>
  )
}
