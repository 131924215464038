import React from 'react'
import "./our_services.css";
import img1 from "../../img-components/COULD.png";

export const OUR_SERVICES = (props) => {

  return (
    <div className='our_services-container' id="services">
      <div className='capa-fondo'>
      </div>
      <div className='rombo'>
      </div>
      <div className='our-services-grid'>
        <div className='ourservg1'>
          <div className='services-title'>
            <h2>{props?.ingles === true ?"OUR ":"NUESTROS "}<span>{props?.ingles===true?"SERVICES":"SERVICIOS"}</span></h2>
            {
              props?.ingles===true?
              <p>
                In computer tools we offer personalized solutions to face the challenges of your company 
                in an efficient manner.
              </p>
              :<p>
                En Herramientas Informáticas ofrecemos soluciones personalizadas para afrontar los retos
                de su empresa de manera eficiente.
              </p>
            }
            <img src={img1}
            ></img>
          </div>
        </div>
        <div className='ourservg2'>
          <div className='sec1a'>
            <section className='sec-cont-c'>
              <div className='circle-serv'>
                <p>1</p>
              </div>
            </section>
            <section className='sec-cont-p'>
              {
                props?.ingles===true?
                <p>
                  <span className='secc-black-weight-p'>Cloud software development:</span> Creating tailored solutions for the cloud, avoiding additional investments
                   in hardware. Our developments are versatile, covering mobile applications, websites, and client modules 
                   for interaction between local servers and the cloud.
                </p>
                :<p>
                  <span className='secc-black-weight-p'>Desarrollo de Software en Nube:</span> Creamos soluciones adaptadas para la nube, 
                  evitando inversiones adicionales en hardware. Nuestros desarrollos son versátiles, abarcando
                  aplicaciones móviles, sitios web, y módulos de cliente para la interacción entre
                  servidores locales y la nube.
                </p>
              }
            </section>
          </div>
          <div className='sec1b'>
            <section className='sec-cont-c'>
              <div className='circle-serv'>
                <p>2</p>
              </div>
            </section>
            <section className='sec-cont-p'>
              {
                props?.ingles===true?
                <p>
                  <span className='secc-black-weight-p'>Software as a service (SAAS):</span> Offering a suite of tools that includes electronic billing, 
                  supplier portals, field sales system, administrative tools, business intelligence, advanced 
                  analytics, and process automation with advanced AI.
                </p>
                :<p>
                 <span className='secc-black-weight-p'>Software as a Service (SAAS):</span> Ofertamos una suite de herramientas que incluyen facturación
                  electrónica, portales para proveedores, sistemas de ventas en campo, herramientas administrativas,
                  inteligencia de negocio, analitica avanzada y automatización de procesos.
                </p>
              }
            </section>
          </div>
          <div className='sec1c'>
            <section className='sec-cont-c'>
              <div className='circle-serv'>
                <p>3</p>
              </div>
            </section>
            <section className='sec-cont-p'>
              {
                props?.ingles===true?
                <p>
                  <span className='secc-black-weight-p'>Managed cloud services:</span> We facilitate the implementation and management of cloud services, including Google 
                  Workspace and the migration of ERP systems, such as SAP, ensuring their optimization and ongoing management.
                </p>:
                <p>
                  <span className='secc-black-weight-p'>Servicios Administrados de Nube:</span> Facilitamos la implementación y gestión de servicios en la nube, 
                  incluyendo Google Work Space y la migración de sistemas ERP como SAP, garantizando su optimización y 
                  administración continua.
                </p>
              }
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
