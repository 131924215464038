import React, { useEffect, useState } from 'react';
import HeroSlider, { Overlay, Slide, MenuNav, Nav, AutoplayButton } from "hero-slider";
import img2 from "../../img-components/img4.jpg";
import s2 from "../../img-components/slider/slider2.png";
import imgnew from "../../img-components/img-portada-blog.webp";

import "./slider.css";

export const SLIDER = (props) => {

  const [loaded, setLoaded] = useState(0);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setLoaded((prevProgress) => prevProgress + 1);
    }, 100); 

    if (loaded >= 100) {
      clearInterval(timer);
      setLoaded(0);
    }
    return () => {
      clearInterval(timer);
    };
  }, [loaded]);


  return (
    <div className='container-slider' id='home'>
       <HeroSlider
          // autoplay
          height="100vh"
          
          controller={{
            initialSlide: 1,
            slidingDuration: 1000,
            slidingDelay: 0,
            onSliding: (nextSlide) =>
              console.debug("onSliding(nextSlide): ", nextSlide, setLoaded(0)),
            onBeforeSliding: (previousSlide, nextSlide) =>
              console.debug(
                "onBeforeSliding(previousSlide, nextSlide): ",
                previousSlide,
                nextSlide,
                setLoaded(0)
              ),
            onAfterSliding: (nextSlide) =>
              console.debug("onAfterSliding(nextSlide): ", nextSlide, setLoaded(0))
          }}
      >
      <Slide
        shouldRenderMask
        background={{
          // backgroundColor: '#8A8A8A',
          maskBackgroundBlendMode: 'luminosity',
          backgroundImageSrc: imgnew,
          backgroundAnimation: 'fade'     
        }}
      >
        <Overlay>
          <div className='container-text'>
              <p className='title-slider animate__animated animate__fadeIn animate__slow'>HERRAMIENTAS <span className='title-span2'>INFORMÁTICAS</span></p>
              <div className='subtile animate__animated animate__fadeIn animate__slow'>{props.ingles===true?"We develop solutions to enhance your work.":"Desarrollamos soluciones para facilitar tu trabajo"}</div>
              <div className='microsite-solutions'>
                  <button
                    onClick={()=>window.location="/solutions_custom"}
                  >Soluciones Personalizadas</button>
              </div>
          </div>
        </Overlay>

      </Slide>
      <Slide
        shouldRenderMask
        background={{
          // backgroundColor: '#8A8A8A',
          maskBackgroundBlendMode: 'luminosity',
          backgroundImageSrc: s2,
          backgroundAnimation: 'zoom' 
        }}
      >
        
      <Overlay>
          <div className='container-text'>
              <p className='title-slider'>{props.ingles===true?"LEARN MORE ":"CONOCE MÁS ACERCA "}<span className='title-span'>{props.ingles===true?"ABOUT US":"DE NOSOTROS"}</span></p>
              <div className='subtile'>{props.ingles===true?"Mission, Vision and Values.":"Misión, Visión y Valores."}</div>
              <div className='button-more'>
                <button onClick={()=>window.location="#about"}>{props.ingles===true?"Read more...":"Leer más..."}</button>
              </div>
          </div>
        </Overlay>

      </Slide>
      
      
    <Nav></Nav>
    </HeroSlider>      
    </div>
  )
}
