import React, { useState } from 'react';
import "./servicescloud.css";
import imgaws from "../../img-components/aws-partner.png";
import icono1 from "../../img-components/icono/cloud-service.png";
import icono2 from "../../img-components/icono/eficiencia.png";
import icono3 from "../../img-components/icono/monitor.png";

import iconos1 from "../../img-components/icono/gestion.png";
import iconos2 from "../../img-components/icono/reduccion.png";
import iconos3 from "../../img-components/icono/carga-en-la-nube.png";
import iconos4 from "../../img-components/icono/apoyo-tecnico.png";
import iconos5 from "../../img-components/icono/nube.png";
import { NAVBAR2 } from './navbar/navbar';
export const SERVICESCLOUD = () => {

  const [ingles, setingles]=useState(  
    localStorage.getItem("lenguaje_hi") === null
      ? false
      : localStorage.getItem("lenguaje_hi") === "true"
      ? true
      : false
);


  return (
    <>
    <NAVBAR2 ingles={ingles} setingles={setingles}></NAVBAR2>
    <div className='services-cloud-container'>
      <div className='services-cloud-title'>
        <div className='capa'></div>
        <h2>{ingles===true?"Cloud Managed Services":"SERVICIOS GESTIONADOS EN LA NUBE"}</h2>
      </div>
      <div className='services-cloud-body'>
        <h2>
          {
            ingles===true?"Optimize your operations and let the cloud work for you.":" Optimiza tus operaciones y deja que la nube trabaje por ti."
          }
        </h2>
        {
          ingles===true?
          <p>
            Transform your cloud resources into efficient, self-managing assets, allowing your 
            business to grow without technical concerns.
          </p>:
          <p>
            Transforma tus recursos en la nube en activos eficientes y autoadministrables, 
            permitiendo que tu negocio crezca sin preocupaciones técnicas.
          </p>
        }
        
      </div>
      <div className='services-cloud-columns'>
        <div className='col1cloud gridcloud'>
          <img src={icono1} alt=''></img>
          <h2>{ingles===true?"Superior Experience in Cloud Managed Services":"Experiencia Superior en Servicios Gestionados en la Nube"}</h2>
          {
            ingles===true?
            <p>
              With the ever-evolving technological landscape, maintaining and managing cloud resources is challenging. At Herramientas Informáticas, 
              we offer cutting-edge management solutions, ensuring that your AWS infrastructure operates with utmost efficiency.
            </p>:
            <p>
              Con el panorama tecnológico en constante evolución, mantener y gestionar 
              recursos en la nube se vuelve un reto. En Herramientas Informáticas, ofrecemos 
              soluciones de gestión de vanguardia, garantizando que tu infraestructura en AWS 
              opere con la máxima eficiencia.
            </p>
          }
          
        </div>
        <div className='col2cloud gridcloud'>
        <img src={icono2} alt=''></img>
          <h2>{ingles===true?"Automation and Efficiency":"Automatización y Eficiencia"}</h2>
          {
            ingles===true?
            <p>
              Automate repetitive tasks, scale resources according to demand, and ensure your 
              cloud infrastructure always remains optimized.
            </p>:
            <p>
              Automatiza tareas repetitivas, escala recursos según la demanda y garantiza que 
              tu infraestructura en la nube esté siempre optimizada.
            </p>
          }
          
        </div>
        <div className='col3cloud gridcloud'>
        <img src={icono3} alt=''></img>
          <h2>{ingles===true?"Continuous Monitoring":"Monitorización Continua"}</h2>
          {
            ingles===true?
            <p>
              Oversee the performance and health of your resources, guaranteeing uninterrupted and efficient functioning.
            </p>:
            <p>
              Supervisa el desempeño y salud de tus recursos, garantizando una operación ininterrumpida y eficiente.
            </p>
          }
          
        </div>
      </div>
      <div className='services-cloud-soluciones' id='services-cloud-soluciones'>
        <h2>{ingles===true?"Advanced Cloud Management Solutions":"Soluciones de Gestión Avanzadas en la Nube"}</h2>
        <div className='linea-title'></div>
        <div className='services-sol-grid'>
          <div className='solgrid1'>
            <img src={iconos1} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Resource Management":"Administración de Recursos"}</h2>
            {
              ingles===true?
              <p>
                Keep your cloud services updated, secure, and tailored to the evolving needs of your business.
              </p>:
               <p
                >Mantén tus servicios en la nube actualizados, seguros y adaptados a las necesidades cambiantes de tu negocio.
              </p>
            }
           
          </div>
          <div className='solgrid2'>
          <img src={iconos2} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Cost Optimization":"Optimización de Costos"}</h2>
            {
              ingles===true?
              <p>
                Ensure you're maximizing the value of your cloud investment by adjusting and tailoring resources as required.
              </p>:
              <p>Asegura que estás obteniendo el máximo valor de tu inversión en la nube, ajustando y adaptando recursos según tus necesidades.</p>
            }
            
          </div>
          <div className='solgrid3'>
          <img src={iconos3} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Backup and Recovery":"Respaldos y Recuperación"}</h2>
            {
              ingles===true?
              <p>
                Implement robust data backup and recovery strategies, safeguarding business continuity under all circumstances.
              </p>:
              <p>
                Implementa estrategias de respaldo y recuperación de datos para garantizar 
                la continuidad del negocio en cualquier escenario.
              </p>
            }
            
          </div>
        </div>
        <div className='services-sol-grid'>
          <div className='solgrid4'>
          <img src={iconos4} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Specialized Technical Support":"Soporte Técnico Especializado"}</h2>
            {
              ingles===true?
              <p>
                Gain access to a team of experts ready to assist you, ensuring seamless and uncomplicated cloud operations.
              </p>:
               <p>
                Accede a un equipo de expertos dispuestos a ayudarte en cualquier momento, asegurando que tus operaciones en 
                la nube sean fluidas y sin complicaciones.
              </p>
            }
            </div>
          <div className='solgrid5'>
          <img src={iconos5} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Efficiency in the Cloud Simplified":"La Eficiencia en la Nube Simplificada"}</h2>
            {
              ingles===true?
              <p>
                With Herramientas Informáticas, cloud management becomes transparent and straightforward, 
                letting you focus on your business's core functions.
              </p>:
              <p>
                Con Herramientas Informáticas, la gestión en la nube es transparente y sencilla, 
                permitiéndote centrarte en lo que realmente importa: tu negocio.
              </p>
            }
            
          </div>
        </div>
      </div>
    </div>
    </>
    
  )
}
