import React, { useState } from 'react'
import "./certificates.css";
import { CARRUSEL_AWS } from './carrusel';
import img1 from "../../img-components/cert1.png";
import img2 from "../../img-components/cert2.png";
import img3 from "../../img-components/cert3.png";



export const CERTIFICATES = (props) => {
  return (
    <div className='certificates-container'>

        <div className='aws-advantages'>
            <p className='title-advtanges'>
              {
                props.ingles===true?
                "Discover the advantages of the AWS expert guide":
                "Descubra las ventajas que aporta la guía de expertos de AWS"
              }
              </p>
            <div className='advantages-seccion'>
               <div className='advantages-grid'>
                  <div className='adv1 advitem' onClick={()=>window.location="/lambda/cloud-managed-services"}>
                    <p>{props.ingles===true?"Cloud Managed Services":"Servicios gestionados en la nube"}</p>
                  </div>
                  <div className='adv2 advitem' onClick={()=>window.location="/lambda/database-services"}>
                    <p>{props.ingles===true?"Database Service ":"Servicio de base de datos"}</p>
                  </div>
                  <div className='adv3 advitem' onClick={()=>window.location="/lambda/cloud-adoption"}>
                    <p>{props.ingles===true?"Cloud Adoption":"Adopción de la nube"}</p>
                  </div>
               </div>
            </div>

        </div>
        
        <div className='certi-aws' id='aws-img1'>
          <div className='capa'></div>
          <div className='certi-aws-text'>
            <h2 className='h2-cert'> 
              {
                props.ingles===true?
                <>
                  <span>AWS</span> Certifications
                </>:
                <>
                  <span>AWS</span> Certificaciones
                </>
              }
            </h2>
            <p>
              {
                props.ingles===true?
                "We hold AWS certifications in Developer Associate, Cloud Practitioner, and Solution Architect, showcasing our expertise and proficiency in cloud solutions.":
                "Contamos con las certificaciones AWS de Developer Associate, Cloud Practitioner y Solution Architect, demostrando nuestra competencia y habilidad en soluciones en la nube."
                
              }
              </p>
            <div className='escudos-aws'>
              
              <img src={img2}></img>
              <img src={img3}></img>
              <img src={img1}></img>
            </div>
          
          </div>
        </div>

        <div className='comp_aws' id='comp_aws'>
          <p>{props.ingles===true?"AWS Competencies":"Múltiples competencias de AWS"}</p>´
          <CARRUSEL_AWS ingles={props.ingles}></CARRUSEL_AWS>
        </div>
    </div>
  )
}
