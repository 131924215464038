import React, { useEffect, useState } from 'react';
import "./slide_aws.css";
import img1 from "../../img-components/slide1-aws.png";
import img2 from "../../img-components/aws-partner.png";

export const SLIDE_AWS = (props) => {

  return(
    <>
        <div className='slide_container'>
          <div className='slid-grid'>
            <div className='slide-text'>
              <h2>{props.ingles===true?"Cloud Consulting Services":"Servicios de Consultoria en la Nube"}</h2>
              {
                props.ingles===true?
                <p>
                 Professional cloud services provided by experienced AWS experts.
                </p>:
                <p>Servicios profesionales en la nube brindados por expertos experimentados de AWS</p>
              }
              
            </div>
            <div className='medalla-aws'>
              <img src={img2} alt='' onClick={()=>window.location="https://partners.amazonaws.com/partners/0010L00001u3q7ZQAQ/Herramientas%20Informaticas"}></img>
            </div>
          </div>
        </div>
        
    </>
  )
  
}
