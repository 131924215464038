import React, { useState } from 'react';
import { NAVBAR } from './navbar/navbar';
import { SLIDE } from './slide/slide';
import { SALES } from './secctions/sales';
import { INVENTORY } from './secctions/inventory';
import { ASSETS_MC } from './secctions/assets';
import { PETITIONS_MC } from './secctions/petition';

export const SOLUTIONS_CUSTOM = () => {
  
  const [ingles, setingles]=useState(
    localStorage.getItem("lenguaje_hi") === null
      ? false
      : localStorage.getItem("lenguaje_hi") === "true"
      ? true
      : false
);

  return (
    <div>
        <NAVBAR ingles={ingles} setingles={setingles}></NAVBAR>
        <SLIDE ingles={ingles}></SLIDE>
        <SALES ingles={ingles}></SALES>
        <INVENTORY ingles={ingles}></INVENTORY>
        <ASSETS_MC ingles={ingles}></ASSETS_MC>
        <PETITIONS_MC ingles={ingles}></PETITIONS_MC>
        
    </div>
  )
}
