import React, { useEffect, useState } from 'react';
import "./slide.css";
import img1 from "../../../img-components/rombo.png";
import imgg from "../../../img-components/cloud_400x300.gif";

export const SLIDE = (props) => {

  return(
    <>
        <div className='slide_container-sc'>
          <div className='slid-grid'>
            <div className='slide-text'>
              <h2>{props?.ingles===true?"CUSTOMIZED SOLUTIONS":"SOLUCIONES PERSONALIZADAS"}</h2>
              {
                props.ingles===true?
                <p>
                 We offer various cloud-adapted solutions that are used in many organizations 
                 to enhance productivity and simplify work
                </p>:
                <p>
                  Contamos con diferentes soluciones adaptadas para la nube que
                    son usados en varias organizaciones para mejorar la productividad
                    y simplificar el trabajo.
                </p>
              }
            </div>
            <div className='medalla-aws'>
              <img src={img1}></img>
            </div>
          </div>
        </div>
        


       
    </>
  )
  
}
