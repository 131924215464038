import React from 'react';
import img1 from "../../../img-components/rombo7.png";
import img2 from "../../../img-components/rombo9.png";
import img3 from "../../../img-components/rombo10.png";
import "./invoice.css";

export const INVOICE = (props) => {
  return (
    <div className='invoice-container'>
        <div className='invoice-section'>
            <div className='inv-sec-g1'>
                <img src={img1}></img>
            </div>
            <div className='inv-sec-g2'>
                <h2>{props?.ingles === true ? "INVOICING":"FACTURACIÓN"}</h2>
                {
                    props?.ingles === true ? 
                    <>
                        <p>
                            We facilitate the invoice generation process according to the specifications 
                            set by the Secretary of Finance and Public Credit of the Mexican government 
                            (all updates are included).
                        </p>
                        <p>
                            It was designed to ensure our clients save time and avoid rework in this 
                            process through our automated systems.
                        </p>
                    </>:
                    <>
                        <p>
                            Facilitamos el proceso de generación de facturas de acuerdo
                            a las especificaciones que señala la Secretaria de Hacienda y 
                            Crédito Público del Gobierno de México (todas las actualizaciones
                            están incluidas)
                        </p>
                        <p>
                            Fue diseñado para garantizar a nuestros clientes el ahorro de tiempo
                            y evite retrabajos en este proceso por medio de nuestros procesos automatizados.
                        </p>
                    </>
                }
                
            </div>
        </div>
        <div className='expense-section'>
            <div className='exp-sec-g1'>
                <h2>{props?.ingles === true ? "BUYING":"COMPRAS"}</h2>
                {
                    props?.ingles === true ? 
                    <p>
                        This application was developed to expedite the receipt of invoices and 
                        manage them easily for payment scheduling and expense policy recording.
                    </p>:
                    <p>
                        Esta aplicación fue desarrollada para agilizar la recepción de las 
                        facturas y gestionarlas fácilmente para programación de pagos y 
                        registro de pólizas de gastos.
                    </p>
                }
                
            </div>
            <div className='exp-sec-g2'>
                <img src={img2}></img>
            </div>
        </div>
        <div className='supli-section'>
            <div className='sup-sec-g1'>
                <img src={img3}></img>
            </div>
            <div className='sup-sec-g2'>
                <h2>{props?.ingles===true ?"SUPPLIER PORTAL":"PORTAL DE PROVEEDORES"}</h2>
                {
                    props?.ingles === true ?
                    <p>
                        This module provides a secure and reliable access for suppliers to submit 
                        their invoices, which are then integrated into the planning and payment 
                        processes for suppliers.
                    </p>:
                    <p>
                        Este módulo brinda un acceso seguro y confiable para que lo 
                        proveedores entreguen sus facturas y estas, a su vez, se integren
                        en la planeacion y pago a proveedores.
                    </p>
                }
               

                <div className='text-a-sup'>
                    {
                        props?.ingles === true ?
                        <p>
                            It is important to note that we have automated validation with the SAT 
                            (Mexican Tax Administration Service) to ensure the validity of these documents.
                        </p>:
                        <p>
                            Es importante indicar que tenemos automatizada la validación con el SAT
                            para asegurar la vigencia de dichos documentos.
                        </p>
                    }
                    
                </div>
            </div>
        </div>
    </div>
  )
}
