import React from 'react';
import "./assets.css";
import img1 from "../../../img-components/icon-ass.png";
import img2 from "../../../img-components/ass ing.png";

export const ASSETS_MC = (props) => {
  return (
    <div className='asset-container'>
        <div className='assets-section'>
            <h2>{props?.ingles === true ?"ASSETS":"ACTIVOS"}</h2>
            <div className='columns-assts'>
                <div className='colass1'>
                    {
                        props?.ingles === true ?
                        <p>
                            With this module, it is possible to manage assets used for sale, 
                            enabling the registration of their receipt, assignment, maintenance records, 
                            and tracking until the end of their useful life.
                        </p>:
                        <p>
                            Con este módulo se puede llevar el control de los activos que se
                            ocupan para venta, se puede registrar su recepción, asignación, registrar
                            mantenimientos y hacer un seguimiento hasta el final 
                            de su vida útil.
                        </p>
                    }
                </div>
                <div className='colass2'>
                    {
                        props?.ingles===true ? 
                        <p>
                            Within the assets, it is possible to control display units, freezers, 
                            refrigerators, and a wide range of display units for both cold and dry product chains.
                        </p>:
                        <p>
                            Dentro de los activos, se puede controlar exhibidores, congeladores,
                            refrigeradores y una amplia gama de exhibidores para cadena de frío y secos.
                        </p>
                    }
                </div>
            </div>
            <div className='asst-icon-desc'>
                {
                    props?.ingles === true ?
                    <img src={img2}></img>:
                    <img src={img1}></img>
                }
                
            </div>
        </div>

    </div>
  )
}
