import React from 'react';
import { INVOICE } from './sections/invoice';

export const SAAS_SECTION = (props) => {
  return (
    <div>
        <INVOICE ingles={props?.ingles}></INVOICE>
    </div>
  )
}
