import React, { useEffect, useRef } from 'react'
import "./seccion.css";
import flag from "../../img-components/icono/hi1.png";
import goo from "../../img-components/icono/hi2.png";
import security from "../../img-components/icono/hi3.png";
import img1 from "../../img-components/img3.jpg";
import img2 from "../../img-components/img4.jpg";
import img3 from "../../img-components/img5.png";

//valores
import v1 from "../../img-components/valores/lealtad.png";
import v2 from "../../img-components/valores/lider.png";
import v3 from "../../img-components/valores/confi.png";
import v4 from "../../img-components/valores/perseverancia.png";
import v5 from "../../img-components/valores/servicio.png";
import v6 from "../..//img-components/valores/sinergia.png";

export const SECCION = (props) => {

  const sectionRef = useRef(null);
  const sectionRef2 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
        
          document.getElementById("p1").classList.add("animate__fadeInDown");
          document.getElementById("p2").classList.add("animate__fadeInDown");
          document.getElementById("p3").classList.add("animate__fadeInDown");

          document.getElementById("div1").classList.add("animate__fadeInLeft");
          document.getElementById("img1").classList.add("animate__flipInY");
          document.getElementById("div2").classList.add("animate__fadeInRight");
          document.getElementById("img2").classList.add("animate__flipInY");
          document.getElementById("div3").classList.add("animate__fadeInLeft");
          document.getElementById("img3").classList.add("animate__flipInY");
          

        } else {
          document.getElementById("p1").classList.remove("animate__fadeInDown");
          document.getElementById("p2").classList.remove("animate__fadeInDown");
          document.getElementById("p3").classList.remove("animate__fadeInDown");

          document.getElementById("div1").classList.remove("animate__fadeInLeft");
          document.getElementById("img1").classList.remove("animate__flipInY");
          document.getElementById("div2").classList.remove("animate__fadeInRight");
          document.getElementById("img2").classList.remove("animate__flipInY");
          document.getElementById("div3").classList.remove("animate__fadeInLeft");
          document.getElementById("img3").classList.remove("animate__flipInY");
          
        }
      },
      { 
        root: null,
        threshold: 0.1,
      }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          document.getElementById("h2").classList.add("animate__fadeIn");
          document.getElementById("cart1").classList.add("animate__fadeIn");
         document.getElementById("cart2").classList.add("animate__fadeIn");
         document.getElementById("cart3").classList.add("animate__fadeIn");
         document.getElementById("cart4").classList.add("animate__fadeIn");
         document.getElementById("cart5").classList.add("animate__fadeIn");
         document.getElementById("cart6").classList.add("animate__fadeIn");
        } else {
          document.getElementById("h2").classList.remove("animate__fadeIn");
          document.getElementById("cart1").classList.remove("animate__fadeIn");
          document.getElementById("cart2").classList.remove("animate__fadeIn");
          document.getElementById("cart3").classList.remove("animate__fadeIn");
          document.getElementById("cart4").classList.remove("animate__fadeIn");
          document.getElementById("cart5").classList.remove("animate__fadeIn");
          document.getElementById("cart6").classList.remove("animate__fadeIn");
        }
      },
      { 
        root: null,
        threshold: 0.1,
      }
    );
    if (sectionRef2.current) {
      observer.observe(sectionRef2.current);
    }

    return () => {
      if (sectionRef2.current) {
        observer.unobserve(sectionRef2.current);
      }
    };
  }, []);

  return (
    <>
    <div className='container-seccion' ref={sectionRef}>
        <div className='seccionA' id='about'>
            <div className='div-seccionA-1'>
                <p className='title2 animate__animated' id='p1'>
                  {props.ingles===true?"MORE":"MÁS DE"}
                </p>
                <p className='title1 animate__animated' id='p2'>
                  {props.ingles===true?"ABOUT US":"NOSOTROS"
                }</p>
            </div>
            <div className='div-seccionA-2'>
                <p className='text-sec2 animate__animated' id='p3'>
                  {
                    props.ingles===true?
                    "Our company was established with the goal of offering innovative IT tools and meeting our customers' day-to-day challenges":
                    "Somos una empresa creada con el fin de brindar herramientas informáticas innovadoras y responder a los retos que se presentan dia a dia a nuestros clientes."
                  }
                </p>
            </div>
            <div className='div-seccionA-3'>
              <div className='secc3a'>
                <div className='grid1 animate__animated' id='div1'>
                  <div className='Mision-p'>
                    <h2>{props.ingles===true?"MISSION":"MISIÓN"}</h2>
                    <p>
                      {props.ingles===true?
                       "We strive to create and offer cutting-edge technological tools, empowering our customers to reach their goals by delivering unparalleled service.":
                       "DESARROLLAR Y PROVEER HERRAMIENTAS TECNOLÓGICAS CON EL FIN DE QUE NUESTROS CLIENTES ALCANCEN SUS METAS, BRINDANDO EL MEJOR SERVICIO."
                      }
                    </p>
                  </div>
                </div>
                <div className='grid2 animate__animated' id='img1'>
                  <div className='fondo-icon'>
                    <img alt="" src={flag}></img> 
                  </div>
                  <div className='line'></div>
                </div>
                <div className='grid3' id='esp1'></div>
              </div>
              <div className='secc3b'>
                <div className='grid1'></div>
                <div className='grid2'>
                  <div className='fondo-icon2 animate__animated' id='img2'>
                    <img alt="" src={goo}></img>
                  </div>
                  <div className='line'></div>
                </div>
                <div className='grid3 animate__animated' id='div2'>
                  <div className='Mision-p'>
                    <h2>{props.ingles===true?"VISION":"VISIÓN"}</h2>
                    <p>
                      {props.ingles===true?
                       
                       "Our vision is to become the most trustworthy company, dedicated to streamlining people's work."  
                       :"SER LA EMPRESA MÁS CONFIABLE PARA FACILITAR EL TRABAJO DE LAS PERSONAS."
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className='secc3c'>
              <div className='grid1'>
                <div className='Mision-p animate__animated' id='div3'>
                    <h2>{props.ingles===true?"VALUES":"VALORES"}</h2>
                    <p>
                      {props.ingles===true?
                      "Our core values encompass unwavering loyalty, exceptional service, unwavering reliability, steadfast perseverance, synergistic collaboration, and inspirational leadership.":
                      "EN LA EMPRESA CONTEMPLAMOS A FONDO LOS VALORES DE LEALTAD, SERVICIO, CONFIABILIDAD, PERSEVERANCIA, SINERGIA, LIDERAZGO."
                      }
                    </p>
                </div>
              </div>
                <div className='grid2 animate__animated' id='img3'>
                  <div className='fondo-icon3'>
                    <img alt="" src={security}></img> 
                  </div>
                </div>
                <div className='grid3'>
                  
                </div>
              </div>
            </div>
        </div>
    </div>
    <div className="container-seccion2" ref={sectionRef2} id='valores-seccion'>
      <div className='capa-black'></div>
      <h2 className='animate__animated' id='h2'>{props.ingles===true?"VALUES":"Nuestros Valores"}</h2>
    <div className='carts-values'>

      <div className='cart-box animate__animated animate__slow' id='cart1'>
        <div className='cart' >
          <div className='cara'>
            <img src={v1} alt=''></img>
            <h2>{props.ingles===true?"LOTALTY":"LEALTAD"}</h2>
          </div>
          <div className='cara detras'>
            <img src={v1} alt=''></img>
              <p>
                {props.ingles===true?
                  "We forge strong bonds of fidelity with our clients and collaborators, fulfilling commitments efficiently, comprehensively, and in accordance with their requirements.":
                  "Creamos vínculos de fidelidad con nuestros clientes y colaboradores, asumiendo los compromisos contraídos de forma eficaz, completa y de acuerdo a lo requerido"
                }
                </p>
            <div className='oscuro-div'></div>
          </div> 
        </div>
      </div>
      <div className='cart-box animate__animated animate__slow' id='cart2'>
        <div className='cart'>
          <div className='cara'>
            <img src={v2} alt=''></img>
            <h2>{props.ingles===true?"LEADERSHIP":"LIDERAZGO"}</h2>
          </div>
          <div className='cara detras'>
            <img src={v2} alt=''></img>
            {
              props.ingles===true?
              <p>
                We understand the importance of influencing, motivating, organizing, and taking action to achieve 
                objectives. This is what has led us to establish ourselves as the leading provider in our field, offering 
                the best technological solutions.
              </p>:
              <p>
                Entendemos la importancia de influir, motivar, organizar y llevar acciones para lograr los objetivos, 
                esto es lo que nos ha llevado a consolidarnos como el proveedor más importante en nuestra área brindando 
                las mejores soluciones tecnológicas.
              </p>
            }
            <div className='oscuro-div'></div>
          </div> 
        </div>
      </div>
      <div className='cart-box animate__animated animate__slow' id='cart3'>
        <div className='cart'>
          <div className='cara'>
            <img src={v3} alt=''></img>
            <h2>{props.ingles===true?"RELIABILITY":"CONFIABILIDAD"}</h2>
          </div>
          <div className='cara detras'>
            <img src={v3} alt=''></img>
            {
              props.ingles===true?
              <p>As an organization, we are dedicated to upholding the highest level of confidentiality 
                for our clients and collaborators, conducting ourselves with ethics, honesty, and integrity 
                in all our endeavors.
              </p>:
               <p>
                Somos una organización comprometida a mantener la más alta privacidad de la información de nuestros 
                clientes y colaboradores actuando con ética, honestidad e integridad en nuestras acciones.
               </p>
            }
            <div className='oscuro-div'></div>
          </div> 
        </div>
      </div>
      <div className='cart-box animate__animated animate__slow' id='cart4'>
        <div className='cart'>
          <div className='cara'>
            <img src={v4} alt=''></img>
            <h2>{props.ingles===true?"PERSEVERANCE":"PERSEVERANCIA"}</h2>
          </div>
          <div className='cara detras'>
            <img src={v4} alt=''></img>
            {
              props.ingles===true?
              <p>
                We continuously seek opportunities for improvement and growth. We work diligently, striving 
                day by day to deliver the best performance and achieve our goals.
              </p>:
              <p>
                Buscamos identificar constantemente oportunidades de mejora y crecimiento, 
                trabajamos arduamente esforzándonos día a día para dar el mejor desempeño y
               alcanzar nuestros objetivos.
              </p>
            }
            <div className='oscuro-div'></div>
          </div> 
        </div>
      </div>
      <div className='cart-box animate__animated animate__slow' id='cart5'>
        <div className='cart'>
          <div className='cara'>
            <img src={v5} alt=''></img>
            <h2>{props.ingles===true?"SERVICE":"SERVICIO"}</h2>
          </div>
          <div className='cara detras'>
            <img src={v5} alt=''></img>
            {
              props.ingles===true?
              <p>
                We offer empathetic, trustworthy, attentive, respectful, fair, supportive, and warm interactions
                that foster a profound sense of loyalty.
              </p>:
              <p>
                Proporcionamos un trato de empatía, confianza, atención, respeto, justicia, apoyo y calidez 
                que origina un sentimiento de fidelidad.
              </p>
            }
            <div className='oscuro-div'></div>
          </div> 
        </div>
      </div>
      <div className='cart-box animate__animated animate__slow' id='cart6'>
        <div className='cart'>
          <div className='cara'>
            <img src={v6} alt=''></img>
            <h2>{props.ingles===true?"SYNERGY":"SINERGIA"}</h2>
          </div>
          <div className='cara detras'>
            <img src={v6} alt=''></img>
            {
              props.ingles===true?
              <p>
                We believe that teamwork, the combination of experiences, and knowledge lead us to achieve better
                 results in reaching our objectives.
              </p>:
              <p>
                Creemos que la colaboración en equipo, la suma de experiencias y conocimientos nos llevan a lograr
                mejores resultados para alcanzar nuestros objetivos.
              </p>
            }
            <div className='oscuro-div'></div>
          </div> 
        </div>
      </div>
    </div>
    


    </div>
    </>
  )
}
