import React from 'react';
import "./custom.css";

import icon1 from "../../img-components/icono/ventas.png";
import icon2 from "../../img-components/icono/inventario.png";
import icon3 from "../../img-components/icono/entrega-de-pedidos.png";
import icon4 from "../../img-components/icono/activos.png"

export const CUSTOM_SOLUTIONS = (props) => {
  return (
    <div className='custom-container' id='custom-container'>
        <div className='capa'></div>
        <div className=''>
            <div className='container-custom-text'>
                <h2>{props?.ingles === true ? "Customized solutions" : "Soluciones Personalizadas"}</h2>
                {
                    props?.ingles===true?
                    <p>
                        We offer various cloud-adapted solutions that are used in many
                        organizations to enhance productivity and simplify work.
                    </p>:
                    <p>
                        Contamos con diferentes soluciones adaptadas para la nube que
                        son usados en varias organizaciónes para mejorar la productividad
                        y simplificar el trabajo.
                    </p>
                }
                <div className='iconos-solutions'>
                    <div className='icon1'>
                        <img src={icon1}></img>
                        <p>{props?.ingles===true?"Sales":"Ventas"}</p>
                    </div>
                    <div className='icon2'>
                        <img src={icon2}></img>
                        <p>{props?.ingles===true?"Inventory":"Inventarios"}</p>
                    </div>
                    <div className='icon3'>
                        <img src={icon3}></img>
                        <p>{props?.ingles === true?"Order Management Module":"Gestión de Pedidos"}</p>
                    </div>
                    <div className='icon4'>
                        <img src={icon4}></img>
                        <p>{props?.ingles===true?"Assets":"Activos"}</p>
                    </div>
                </div>
                <div className='button-cl-b'>
                    <button className='custom-soluction-bottom' onClick={()=>window.location="/solutions_custom"}>
                        {props?.ingles === true?"More Information":"Más Información"}

                    </button>
                </div>
                
            </div>
        </div>
    </div>
  )
}
