import React, { useEffect, useState } from 'react'
import logohi from "../../../img-components/logos/logo-hi.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "./navbar.css";
import MenuIcon from '@mui/icons-material/Menu';

export const NAVBAR2 = (props) => {


    const[menu, setmenu]=useState(false);


    useEffect(() => {
        
        if (menu === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible'; // O 'auto' si prefieres restaurar el scroll
        }
        return () => {
            document.body.style.overflow = 'visible'; // O 'auto' si es necesario
        };
    }, [menu])


  return (
    
    <div className='navbar-container-aws'>
    <div className='grid-menu-aws'>
        <div className='icono-aws'>
            <img src={logohi} onClick={()=>window.location="/"}></img>
        </div>
        <div className='menu-items-aws'>
            <ul className='ul-items-aws'>
                <li><a href="/lambda" data-section="#about" id="item2">{props.ingles===true?"Home":"Inicio"}</a></li>
                <li><a href="#services-cloud-soluciones" data-section="#services-cloud-soluciones" id="item2">{props.ingles===true?"Solutions":"Soluciones"}</a></li>
                
                <li>
                    <div class="language-dropdown-aws">
                        Idioma <ArrowDropDownIcon></ArrowDropDownIcon>
                        <ul class="language-options-aws">
                            {
                                props.ingles===true?
                                <li onClick={
                                    ()=>{
                                        props.setingles(false)
                                        localStorage.setItem("lenguaje_hi", "false");
                                    }}><a href="#">Español</a></li>:
                                <li onClick={
                                    ()=>{
                                        props.setingles(true)
                                        localStorage.setItem("lenguaje_hi", "true");
                                    }
                                }><a href="#">Inglés</a></li>
                            }                 
                        </ul>
                    </div>
                </li>
                
            </ul>
        </div>
        <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
        {
            menu===true?    
                <div className='menu-flotante-cel'>
                    <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
                    <ul className='menu-flotante-items'>
                        <li><a href="/lambda" onClick={()=>setmenu(false)} data-section="#about" id="item2">{props.ingles===true?"Home":"Inicio"}</a></li>
                        <li><a href="#services-cloud-soluciones" onClick={()=>setmenu(false)} data-section="#services-cloud-soluciones" id="item2">{props.ingles===true?"Solutions":"Soluciones"}</a></li>
                    </ul>
                </div>:
                <></>
        }
    </div>
    
</div>
  )
}
