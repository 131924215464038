import React, { useState } from 'react';
import "./servicescloud.css";
import imgaws from "../../img-components/aws-partner.png";
import icono1 from "../../img-components/icono/bd1.png";
import icono2 from "../../img-components/icono/bd2.png";
import icono3 from "../../img-components/icono/bd3.png";

import iconos1 from "../../img-components/icono/gestion.png";
import iconos2 from "../../img-components/icono/reduccion.png";
import iconos3 from "../../img-components/icono/carga-en-la-nube.png";
import iconos4 from "../../img-components/icono/apoyo-tecnico.png";
import iconos5 from "../../img-components/icono/nube.png";
import { NAVBAR2 } from './navbar/navbar';






export const SERVICESBD = () => {


  const [ingles, setingles]=useState(  
    localStorage.getItem("lenguaje_hi") === null
      ? false
      : localStorage.getItem("lenguaje_hi") === "true"
      ? true
      : false
);


  return (
    <>
<NAVBAR2 ingles={ingles} setingles={setingles}></NAVBAR2>
    <div className='services-cloud-container'>
      <div className='services-cloud-title'>
        <div className='capa'></div>
        <h2>{ingles===true?"Database Service":"SERVICIOS DE BASE DE DATOS"}</h2>
        {
          ingles===true?
          <p className='adv-h2'>
            With Herramientas Informáticas, your data is in expert hands, empowering your 
            business to forge ahead with assurance and efficiency.
          </p>:
           <p className='adv-h2'>
            Con Herramientas Informáticas, tus datos están en manos expertas, permitiendo que tu negocio avance con confianza y eficiencia.
           </p>
        }
       
      </div>
      <div className='services-cloud-body'>
        <h2>
          {
            ingles===true?"Turn your data into your business's driving force.":"Transforma tus datos en el motor de tu negocio."
          }
        </h2>
        {
          ingles==true?
          <p>
            Ensure, organize, and access your information with an optimized cloud database, paving the way for quicker and more informed decisions.
          </p>:
          <p>
          Asegura, organiza y accede a tu información con una base de datos en la nube optimizada, permitiendo decisiones más rápidas y eficientes.
          </p>
        }
      </div>
      <div className='services-cloud-columns'>
        <div className='col1cloud gridcloud'>
          <img src={icono1} alt=''></img>
          <h2>
            {
              ingles===true?
              "Database Services by Herramientas Informáticas on AWS":
              "Base de Datos de Herramientas Informáticas en AWS"
            }</h2>
            {
              ingles===true?
              <p>
                In the digital age, data is vital for businesses. We provide robust and scalable services, 
                ensuring the performance and security you need.
              </p>:
              <p>
                En la era digital, los datos son vitales para las empresas. Brindamos servicios 
                robustos y escalables, asegurando el rendimiento y seguridad que necesitas.
              </p>
            }
          
        </div>
        <div className='col2cloud gridcloud'>
        <img src={icono2} alt=''></img>
          <h2>{ingles===true?"Exceptional Performance":"Rendimiento Excepcional"}</h2>
          {
            ingles===true?
            <p>
             Thanks to optimized servers and cutting-edge technology, we ensure fast responses and continuous access.
            </p>:
            <p>
              Gracias a servidores optimizados y tecnología de vanguardia, aseguramos 
              respuestas rápidas y acceso continuo.
            </p>
          }
        </div>
        <div className='col3cloud gridcloud'>
        <img src={icono3} alt=''></img>
          <h2>{ingles===true?"Maximum Security":"Máxima Seguridad"}</h2>
          {
            ingles===true?
            <p>
              With advanced protocols, we guarantee complete protection of your data against threats.
            </p>:
             <p>
             Con protocolos avanzados, garantizamos la protección total de tus datos ante amenazas.
            </p>
          }
          
         
        </div>
      </div>
      <div className='services-cloud-soluciones-bd' id='services-cloud-soluciones'>
      <div className='capa'></div>
        <h2>{ingles===true?"Forefront Database Solutions":"Soluciones de Base de Datos a la Vanguardia"}</h2>
        <div className='linea-title-bd'></div>
        <div className='services-sol-grid-bd'>
          <div className='solgrid1'>
            <img src={iconos1} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Scalability on Demand":"Escalabilidad a Demanda"}</h2>
            {
              ingles===true?
              <p>
                Adapt your database to your business's evolving needs, ensuring efficiency and cost-effectiveness.
              </p>:
              <p>
                Ajusta tu base de datos según las necesidades cambiantes de tu negocio, garantizando eficiencia 
                y costos optimizados.
              </p>
            }
            </div>
          <div className='solgrid2'>
          <img src={iconos3} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Migration and Backup":"Migración y Respaldo"}</h2>
            {
              ingles===true?
              <p>
                Seamlessly transfer data between platforms and guarantee your information's integrity with periodic 
                backups and disaster recovery strategies.
              </p>:
              <p>
                Migra datos sin problemas entre plataformas y asegura la integridad de tu información con respaldos 
                regulares y recuperación en caso de desastre.
              </p>
            }
          </div>
         
      </div>
      <div className='services-sol-grid-bd'>
        <div className='solgrid3'>
          <img src={iconos4} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Optimization and Maintenance":"Optimización y Mantenimiento"}</h2>
            {
              ingles===true?
              <p>
                Our team of experts consistently oversees and maintains your database, ensuring 
                its peak performance and updated status.
              </p>:
              <p>Nuestro equipo de expertos monitorea y mantiene tu base de datos, asegurando que 
                siempre esté actualizada y funcionando a su máximo potencial.
              </p>
            }
           
          </div>
          <div className='solgrid4'>
          <img src={iconos5} alt='' className='imgsolucloud'></img>
            <h2>{ingles===true?"Swift Access and Queries":"Acceso y Consultas Rápidas"}</h2>
            {
              ingles===true?
              <p>
                With Herramientas Informáticas, you can swiftly access and query your data, streamlining 
                decision-making and business operations.
              </p>:
              <p>Con Herramientas Informáticas, accede a tus datos de manera rápida y efectiva, facilitando
                 la toma de decisiones y la operación del negocio.
              </p>
            }
            
          </div>
        </div>
      </div>
    </div>
    </>
    
  )
}
