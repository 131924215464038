import React from 'react';
import "./seccion_google.css";
import imghi from "../../img-components/logos/logo-hi.png";
import imgoogle from "../../img-components/logos/google.png";
import insig from "../../img-components/google-partner.png";

export const SECCION_GOOGLE = (props) => {
  return (
    <div className='seccion-google-container' id='seccion-google-container'>
        <div className='cap-ggole'>

        </div>
        <div className='seccion-google-info'>
            <div className='logos-goole'>
                <img className='img-google' src={imgoogle} alt=''></img>
                <div className='line-gg'></div>
                <img className="img-hi" src={imghi} alt=''></img>
            </div>
        </div>
        <div className='desc-google'>
            <h2>{props.ingles===true?"WE ARE GOOGLE PARTNERS":"Socios de Google"}</h2>
            {
                props.ingles===false?
                <>
                    <p>Esta asociación significa que estamos al día con las últimas tendencias y tecnologías del mercado 
                        ya que recibimos constantemente formación y recursos de Google.
                    </p>
                    <p>¿Qué significa esto para nuestros clientes?</p>
                    <p>Esta asociación nos permite ofrecer a nuestros clientes servicios aún más eficientes y eficaces. 
                        Al tener acceso directo a los recursos, la formación y el soporte de Google, podemos optimizar 
                        nuestros esfuerzos para ayudar a nuestros clientes a alcanzar sus objetivos con mayor rapidez y 
                        eficacia. Además, nuestros clientes se beneficiarán de las soluciones más innovadoras y actualizadas, 
                        respaldadas por el prestigio y la confianza que viene de trabajar con un socio de Google.
                    </p>
                </>:
                <>
                    <p>This partnership means that we stay up-to-date with the latest market trends and technologies 
                        as we constantly receive training and resources from Google.
                    </p>
                    <p>What does this mean for our clients?</p>
                    <p>This partnership allows us to offer even more efficient and effective services to our clients. With direct 
                        access to Google's resources, training, and support, we can optimize our efforts to help our clients achieve 
                        their goals faster and more effectively. Additionally, our clients will benefit from the most innovative and 
                        up-to-date solutions, backed by the prestige and trust that comes from working with a Google partner.
                    </p>
                
                </>
            }
            
        </div> 
        <div className='div-google-foot'>
            <img src={insig} alt=''></img>
            {
                props.ingles===true?
                <p>
                    Contact us, we can support you with Google Workspace solutions to facilitate your company's email and document management. 
                </p>:
                <p>
                    Contáctenos podemos apoyarle con soluciones de Google Workspace para facilitar la administración de correo y documentos de su empresa.
                </p> 
            }
        </div>   
    </div>
  )
}
