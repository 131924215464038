import React from 'react'
import "./lumigo.css";
import imglum from "../../img-components/lumigo.png";
import imghi from "../../img-components/logos/logo-hi.png";

export const SECCION_LUMIGO = (props) => {
  return (
    <>
    <div className='lumigo_container'>
      <div className='capa-lum'></div>
       <div className='seccion-lum-info'>
            <div className='logos-lum'>
                <img className='img-lum' src={imglum} alt=''></img>
                <div className='line-gg'></div>
                <img className="img-hi" src={imghi} alt=''></img>
            </div>
            <div className='text-content'>
              <p className='title-lumig'>
                {props?.ingles===true?"We are Lumigo Partners":"Socios de Lumigo"}
              </p>
              {
                props?.ingles===true?
              <p className='text-lumigo'>
                Lumigo is a troubleshooting and observability platform that deploys distributed tracing 
                in under 5 minutes with a single click, automatically capturing and contextualizing all 
                of the data developers need to troubleshoot microservice issues in production. 
              </p>:
              <p className='text-lumigo'>
                Lumigo es una plataforma de observación y resolución de problemas que implementa el 
                seguimiento distribuido en menos de 5 minutos con un solo clic, capturando y contextualizando 
                automáticamente todos los datos que los desarrolladores necesitan para solucionar problemas de 
                microservicios en producción. 
              </p>
              }
             {
              props?.ingles===true?
              <p className='text-lumigo'>  Lumigo is the ONLY distributed tracing platform that enriches 
              traces with complete in-context request and response payloads and correlates them to the 
              relevant logs and metrics, enabling developers to resolve issues up to 80% faster.
              </p>:
              <p className='text-lumigo'>  Lumigo es la ÚNICA plataforma de seguimiento distribuida que 
              enriquece los seguimientos con cargas útiles completas de solicitudes y respuestas en contexto y
               las correlaciona con los registros y métricas relevantes, lo que permite a los desarrolladores 
               resolver problemas hasta un 80% más rápido".
            </p>
             }
              
            </div>
            <br/><br/>
            <button onClick={()=>window.location="https://lumigo.io/"} className='buttonlumigo'>
              {props?.ingles===true?"More Information":"Más Información"}
            </button>
        </div>
    </div>
    {/* <div className='footer-lumi'>
        <p>
             
        </p>
       
    </div> */}
    </>
    
  )
}
