import './App.css';
import { SERVICESADOP } from './microsite/advantges.jsx/servicesadop';
import { SERVICESBD } from './microsite/advantges.jsx/servicesbd';
import { SERVICESCLOUD } from './microsite/advantges.jsx/servicescloud';
import { MICROSITE_AWS } from './microsite/microsite';
import { HOME } from './pages/home';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { TARJETA } from './tarjeta/tarjeta';
import { SOLUTIONS_CUSTOM } from './microsite-custom/custom-solutions/solutions';
import { SAAS } from './microsite-custom/saas/saas';

function App() {

  return (
    <div>
      <Router>
          <Switch> 
              <Route path={"/"} exact component={HOME}></Route>
               <Route path={"/solutions_custom"} exact component={SOLUTIONS_CUSTOM}></Route>
               <Route path={"/lambda"} exact component={MICROSITE_AWS}></Route>
               <Route path={"/lambda/cloud-managed-services"} exact component={SERVICESCLOUD}></Route>
               <Route path={"/lambda/database-services"} exact component={SERVICESBD}></Route>
               <Route path={"/lambda/cloud-adoption"} exact component={SERVICESADOP}></Route>
               <Route path={"/contact"} exact component={TARJETA}></Route>
              <Redirect to="/"></Redirect>
          </Switch>
      </Router>
    </div>
  );
}

export default App;
