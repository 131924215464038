import React from 'react'
import "./seccion_aws.css";
import hi from "../../img-components/logos/logo-hi.png";
import aws from "../../img-components/logos/aws.png";
import awsb from "../../img-components/logos/awsb.png";
import awsp from "../../img-components/aws-partner.png";
import awsl from "../../img-components/aws lambda.png";


export const SECCION_AWS = (props) => {
  return (
    <div className='aws-seccion' id='aws'>
        <div className='capa-oscura'></div>
        <div className='container-aws'>
            <div className='logos-aws'>
                <img src={aws} className='log-2'></img>
                <div className='linea'></div>
                <img src={hi} className='log-1'></img>
            </div>
            <div className='eslogan'>
                <h2>
                    {
                        props.ingles===true?
                        "Herramientas Informáticas: Your AWS Cloud Partner for Business Success.":
                        "Herramientas Informáticas: Tu Socio en la nube AWS para el Éxito Empresarial."
                    }
                    
                </h2>
            </div>
            <div className='desc-aws'>
                {
                    props.ingles===true?
                    <>
                    <p>
                        If you are looking for AWS experts, you've found them! At Herramientas Informáticas, we are committed
                         to providing cutting-edge ERP solutions for businesses of all sizes. What sets us apart is our strong 
                         partnership with Amazon Web Services (AWS), backed by AWS competencies and platform certifications.
                    </p>
                    <p>
                        Our expertise in AWS allows us to offer scalable, secure, and highly efficient ERP solutions that can 
                        transform your business. Whether you need to optimize processes, improve data management, or boost productivity, 
                        our solutions are designed to adapt to your specific needs.
                    </p>
                    </>:
                    <>
                     <p>
                        Si estás buscando expertos en AWS, ¡los ha encontrado! En Herramientas Informáticas, estamos comprometidos en brindar soluciones 
                        ERP de vanguardia para empresas de todos los tamaños. Lo que nos distingue es nuestra asociación sólida con Amazon Web Services (AWS),
                        respaldada por competencias de AWS y certificaciones en la plataforma.
                    </p>
                    <p>
                        Nuestra experiencia en AWS nos permite ofrecer soluciones ERP escalables, seguras y altamente eficientes que pueden 
                        transformar su negocio. Ya sea que necesite optimizar procesos, mejorar la gestión de datos o impulsar la productividad,
                        nuestras soluciones están diseñadas para adaptarse a sus necesidades específicas.
                    </p>
                    
                    </>
                }
               
            </div>
            <div className='certificaciones-aws'>
                <div></div>
                <div className='cart-certi'>
                    <img src={awsp}
                        onClick={()=>{
                            window.open("https://partners.amazonaws.com/partners/0010L00001u3q7ZQAQ/Herramientas%20Informaticas")
                        }}
                        title={props.ingles===true?"Click on the image to verify the certification.":'Haz clic en la imagen para verificar la certificación.'}
                    ></img>
                    <img src={awsl} className='log2'
                         onClick={()=>{
                            window.open("https://partners.amazonaws.com/partners/0010L00001u3q7ZQAQ/Herramientas%20Informaticas")
                        }}
                        title={props.ingles===true?"Click on the image to verify the certification.":'Haz clic en la imagen para verificar la certificación.'}        
                    ></img>
                </div>
                <div className='serv-aws'>
                     <h2>{props.ingles===true?"Broad AWS Skill Set":"Amplio conjunto de habilidades de AWS"}</h2>
                    <div className='serv-colum-aws'>
                        <div className='colum1'>
                            {
                                props.ingles===true?
                                <>
                                   <p>Serverless computing service provider</p>
                                   <p>Managed service provider</p>
                                   <p>Cloud migrations</p>
                                </>:
                                <>
                                    <p>Proveedor de servicios de informática sin servidor</p>
                                    <p>Proveedor de servicios gestionados</p>
                                    <p>Migraciones a la nube</p>
                                </>
                            }
                            
                        </div>
                        <div className='colum2'>
                            {
                                props.ingles===true?
                                <>
                                   <p>Cloud solutions development</p>
                                    <p>Business Intelligence solutions development</p>
                                </>:
                                <>    
                                    <p>Desarrollo de soluciones en la nube</p>
                                    <p>Desarrollo de soluciones en business Intelligence</p>
                                </>
                            }
                            
                        </div>
                        <div className='colum3'>
                            <button className='button-aws' 
                                 onClick={()=>window.location="./lambda"}
                            >
                                {props.ingles===true?"More information":"Más Información"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
