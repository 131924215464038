import React from 'react';
import "./sales.css";
import img1 from "../../../img-components/rombo2.png";
import img2 from "../../../img-components/cel1.png";
import cel from "../../../img-components/icono/celweb.png";

export const SALES = (props) => {
  return (
    <>
    <div className='cap-fond'>
    <div className='sales-container-page-mc'>
      <div className='sales-grid-cpm'>
        <div className='salesg1'>
          <img src={img1}></img>
        </div>
        <div className='salesg2'>
          <h2>{props?.ingles === true ? "SALES":"VENTAS"}</h2>
          {
            props?.ingles === true ?
            <p>
              Designed to manage and record sales, indicate the best route to visit 
              clients, provide access to all necessary information for our users to 
              conduct their transactions, negotiations, and achieve the best sales.
            </p>:
            <p>
              Diseñado para llevar el control y registro de las 
              ventas, indicar la mejor ruta de visita a los clientes, 
              brindar acceso a toda la información necesaria para que nuestros
              usuarios realicen sus transacciones, negociaciones y 
              obtenga las mejores ventas.
            </p>
          }
        </div>
        </div>
    </div>
    <div className='sales-real-mc'>
      <div className='sales-real-grid-mc'>
        <div className='salesrg1'>
          <h2>{props?.ingles===true ? "REAL-TIME SALES":"VENTAS EN TIEMPO REAL"}</h2>
          {
            props?.ingles === true ?
            <p>
              Sales can be recorded from your mobile device, this information is automatically 
              retransmitted to our ERP so that the sales processes, cash register, and inventory 
              control can continue.
            </p>:
            <p>
              Se pueden registrar las ventas desde su dispositivo móvil, esta
              información se retransmite automáticamente a nuestro ERP para que continúen
              los procesos de venta, caja y control de inventarios.
          </p>
          }
          <div className='box-real-sales'>
            {
              props?.ingles === true?
              <p>
                At the end of the day's operation, the application automatically 
                sends a summary of the operation to the supervisor via chat.
              </p>:
              <p>
                Al terminar la operación del día, la aplicación
                se encarga de enviar un resumen de la operación al 
                supervisor vía chat de forma automática.
            </p>
            }
            
          </div>
        </div>
        <div className='salesrg2'>
          <img src={img2}></img>
        </div>
      </div>
    </div>
    </div>
    <div className='slogan-movil'>
      <div className='slogan-text'>
      <p className='text-ghost'>HERRAMIENTAS INFORMÁTICAS</p>
      <div className='grid-text-promotion'>
        <img src={cel} className='grdi-slog-g1'></img>
        <div className='grdi-slog-g2'>
            <p className='text-promotion'>
              {
                props?.ingles===true?"Boost your business in the digital world!":"¡Impulsa tu negocio en el mundo digital!"
              }
            </p>
            <p className='text-promotion2'>  
            {
              props?.ingles===true?
              '"With our customized app, reach new horizons."':
              "Con nuestra app personalizada, alcanza nuevos horizontes"
            } 
            </p>
        </div>
      </div>   
      </div>
    </div>
    
    </>
    )
}
