import React, { useState } from 'react'
import { NAVBAR } from './navbar/navbar'
import { SLIDER } from './slider/slider'
import { SECCION } from './seccion/seccion'
import { SERVICES } from './services/services'
import { SECCION_AWS } from './seccion_aws/seccion_aws'
import { SOLUTIONS } from './solutions/solutions';
import { CONTACT } from './contact/contact'
import { FOOTER } from './footer.jsx/footer'
import "./home.css";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import 'animate.css';
import { SECCION_GOOGLE } from './seccion_google/seccion_google'
import { SECCION_LUMIGO } from './seccion_lumigo/seccion_lumigo'
import { OUR_SERVICES } from './our_services/our_services'
import { CUSTOM_SOLUTIONS } from './custom_solutions/custom_solutions'
import { SASS } from './saas/sass'

export const HOME = () => {

  const [ingles, setingles]=useState(
    localStorage.getItem("lenguaje_hi") === null
      ? false
      : localStorage.getItem("lenguaje_hi") === "true"
      ? true
      : false
);

  window.onscroll = function() {
     var button = document.getElementById("scrollToTopButton");
     if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
       document.getElementById("scrollToTopButton").classList.remove("active"); 
       button.style.display = "block";
     } else {
       document.getElementById("scrollToTopButton").classList.add("active"); 
       setTimeout(() => {
          button.style.display = "none";
       }, 1000);  
     }
   };

   const scrollToTop = () => {

    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      const scrollStep = Math.ceil(currentScroll / 10);      
      window.scrollTo(0, currentScroll - scrollStep);
      requestAnimationFrame(scrollToTop);
    }
  };

  const upfunc = () => {
    scrollToTop();
  };

  return (
    <>
      <div className=''>
        <NAVBAR setingles={setingles} ingles={ingles}></NAVBAR>
        <SLIDER ingles={ingles}></SLIDER>
        <SECCION ingles={ingles}></SECCION>
        <OUR_SERVICES ingles={ingles}></OUR_SERVICES>
        <CUSTOM_SOLUTIONS ingles={ingles}></CUSTOM_SOLUTIONS>
        <SASS ingles={ingles}></SASS>
        <SOLUTIONS ingles={ingles}></SOLUTIONS>
        <SECCION_AWS ingles={ingles}></SECCION_AWS> 
        <SECCION_GOOGLE ingles={ingles}></SECCION_GOOGLE>
        <SECCION_LUMIGO ingles={ingles}></SECCION_LUMIGO>
        <CONTACT ingles={ingles}></CONTACT>
        <FOOTER ingles={ingles}></FOOTER>
        
        <button id="scrollToTopButton" onClick={()=>upfunc()}
          className='button-up'
        > 
          <ArrowUpwardIcon></ArrowUpwardIcon>
        </button>
      </div>
    </>
  )
}
