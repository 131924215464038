import React from 'react';
import "./footer.css";
import hi from "../../img-components/logos/logo-hi.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import {CustomDialog} from "react-st-modal";

export const FOOTERAWS = (props) => {



  return (
    <div className='footer-container-aws'>
      <div className='footer-seccions'>
        <div className='logo'>
          <img src={hi} alt=''></img>
          <h2>HERRAMIENTAS INFORMATICAS</h2>
        </div>
        <div className='secc1'>
          <h2>{props.ingles===true?"Services":"Servicios"}</h2>
          <div className='line-2-aws'></div>
          {
            props.ingles===true?
            <>
              <p>Cloud</p>
              <p>Consultancy</p>
              <p>Training</p>
              <p>Technical Support</p>
            </>:
            <>
              <p>Nube</p>
              <p>Consultoria</p>
              <p>Capacitación</p>
              <p>Soporte Técnico</p>
            </>
          }
          
        </div>
        <div className='secc2'>
          <h2>{props.ingles===true?"AWS":"AWS"}</h2>
          <div className='line-2-aws'></div>
          {
            props.ingles===true?
            <>
              <p onClick={()=>window.location="#solutions-aws-secc"}>AWS Solutions</p>
              <p onClick={()=>window.location="#aws-img1"}>Certifications</p>
            </>:
            <>
              <p onClick={()=>window.location="#solutions-aws-secc"}>Soluciones AWS</p>
              <p onClick={()=>window.location="#aws-img1"}>Certificaciones</p>
            </>
          }
          
        </div>
        <div className='secc3'>
          <h2>{props.ingles===true?"About Us":"Nosotros"}</h2>
          <div className='line-2-aws'></div>
          {
            props.ingles===true?
            <>
              <p onClick={()=>window.location="/#about"}>Vision</p>
              <p onClick={()=>window.location="/#about"}>Mission</p>
              <p onClick={()=>window.location="/#about"}>Values</p>
            </>:
            <>
              <p onClick={()=>window.location="/#about"}>Visión</p>
              <p onClick={()=>window.location="/#about"}>Misión</p>
              <p onClick={()=>window.location="/#about"}>Valores</p>
            </>
          }
          
        </div>
        <div className='secc4'>
          <h2>{props.ingles===true?"Contact":"Contacto Directo"}</h2>
          <div className='line-2-aws'></div>
          <div className='redes whatsapp' 
            onClick={()=>{
              window.open("https://api.whatsapp.com/send?phone=5212727230084", "_blank");
            }
          }
          >
            <WhatsAppIcon 
            ></WhatsAppIcon>
            <p>Whatsapp</p>
          </div>
          <div className='redes gmail' onClick={()=>{
            window.location="/#contact";
          }}>
            <EmailIcon></EmailIcon>
            <p>{props.ingles===true?"Email":"Correo Electrónico"}</p>
          </div>
        </div>
      </div>
      <div className='copy'>
        <p>Copyright © 2023 {props.ingles===true?"All rights reserved":"Todos los derechos reservados"} | @herramientasinformaticas</p>
      </div>
    </div>
  )
}
