import React from 'react'
import "./privacidad.css";

export const PRIVACIDAD = (props) => {
  return (
    <div className='privacidad-container'>
       {
        props.item===1?
        <>{
            props.ingles===false?
            <>
            <h2>AVISO DE PRIVACIDAD</h2>
            <p>
            HERRAMIENTAS INFORMÁTICAS con domicilio en Independencia 900, Colonia Barrio Nuevo, de la Ciudad de Orizaba Veracruz, México, correo electrónico: soporte@herramientasinformaticas.com, y teléfono (01-272) 7230084, en adelante HERRAMIENTAS INFORMÁTICAS, es el responsable del uso y protección de sus datos personales, y en cumplimiento a la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, al respecto le informamos lo siguiente:
            </p>
            <p>1.- LOS DATOS PERSONALES QUE SOLICITAREMOS DE USTED, SERÁN LOS SIGUIENTES: </p>
            <ul>
                <li>
                    Datos de identificación, datos de contacto, datos laborales, datos patrimoniales y datos fiscales.
                </li>
                <li>
                    Considere que no en todos los casos se recaba toda la información anterior, lo cual atiende a que no toda la información puede ser proporcionada por el respectivo Titular o requerida para la prestación del servicio.
                </li>
            </ul>

            <p>
               2.- LOS DATOS PERSONALES SERÁN TRATADOS PARA LAS FINALIDADES SIGUIENTES:
            </p>
            <ul>
                <li>Proporcionar los servicios y/o productos solicitados.</li>
                <li>Validar, emitir y enviar comprobantes fiscales digitales.</li>
                <li>Confirmación de pagos. </li>
                <li>Generar expediente del cliente.</li>
                <li>Generar directorio de clientes.</li>
                <li>
                    En los casos en que nuestros desarrollos de software, sirvan de facilitadores o herramientas para cumplir alguna ley o sus reglamentos, sus datos serán tratados de conformidad con el artículo 10 de la Ley Federal de Protección de datos personales en posesión de los particulares. 
                </li>
            </ul>
            <p>3.-  DERECHOS ARCO Y MEDIOS PARA EJERCERLOS:</p>
            <ul>
                <li>
                Como titular de datos personales, usted podrá ejercer los Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición) para el tratamiento de sus datos personales, enviando su solicitud directamente al departamento de "Protección de Datos" a través de la cuenta de correo electrónico: soporte@herramientasinformaticas.com
                </li>
                <li>
                Dicha solicitud deberá contener por lo menos: (a) nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud; (b) los documentos que acrediten su identidad o, en su caso, la representación legal; (c) la descripción alguno de los Derechos ARCO, (d) cualquier otro elemento que facilite la localización de los datos personales y (e) firmar la solicitud al final del escrito.
                </li>
                <li>
                Una vez presentada su solicitud, el departamento de “Protección de Datos” comunicará en un plazo máximo de 15 días hábiles, contados desde la fecha en que se recibió dicha solicitud, la respuesta de la misma. En caso de requerir mayor información y/o documentación necesaria el departamento de “Protección de Datos” se pondrá en contacto con usted en un plazo de 5 días hábiles contados desde la fecha de recepción de su solicitud, por lo que usted contará con 10 días hábiles posteriores a dicha comunicación, para atender este requerimiento. De lo contrario su solicitud se tendrá por no presentada. Dentro de los siguientes 10 días hábiles posteriores a la respuesta anterior, en el caso de que sea procedente su derecho, será aplicado. Si su solicitud es acerca del ejercicio del derecho de acceso, se pondrán sus datos personales a su disposición a través de copias simples y/o documentos electrónicos.
                </li>
            </ul>
            <p>
            4.-  REVOCACIÓN DEL CONSENTIMIENTO PARA EL USO DE DATOS PERSONALES.
            </p>
            <ul>
                <li>
                Usted Titular, en todo momento, podrá revocar su consentimiento para el tratamiento de sus datos personales, conforme al procedimiento previsto en el apartado anterior, haciendo el señalamiento expreso de que desea revocar su consentimiento. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
                </li>
                <li>
                Para mayor información sobre la protección de sus datos personales puede contactar a nuestro departamento de “Protección de Datos” en los medios de contacto señalados en este AVISO DE PRIVACIDAD.
                </li>
            </ul>
            <p>
            5.- . USO O DIVULGACIÓN DE DATOS PERSONALES:
            </p>
            <ul>
                <li>
                HERRAMIENTAS INFORMÁTICAS, utilizará sus datos personales en estricto apego a las finalidades previstas en el presente AVISO DE PRIVACIDAD y durante el tiempo que sea necesario de conformidad con las disposiciones legales aplicables.
                </li>
                <li>
                HERRAMIENTAS INFORMÁTICAS, informa que:  El sitio web de www.herramientasinformaticas.com puede incluir enlaces a sitios web de terceros, que, de accederse, ocasionará que se abandone el sitio web de www.herramientasinformaticas.com, por lo cual HERRAMIENTAS INFORMÁTICAS, no asume ninguna responsabilidad en relación con esos sitios web de terceros.
                </li>
            </ul>
            <p>
            6.- VIGENCIA DEL TRATAMIENTO DE LOS DATOS PERSONALES.
            </p>
            <ul>
                <li>
                    Sus datos personales serán tratados únicamente por el tiempo necesario a fin de cumplir con las finalidades previstas en este AVISO DE PRIVACIDAD y/o de conformidad con lo dispuesto en la LEGISLACIÓN y demás disposiciones legales aplicables relativas a los plazos de conservación de información.
                </li>
            </ul>
            <p>
            7.-  CAMBIOS AL PRESENTE AVISO DE PRIVACIDAD.
            </p>
            <ul>
                <li>
                Los cambios y actualizaciones del presente AVISO DE PRIVACIDAD se harán de su conocimiento en nuestro portal principal www.herramientasinformaticas.com.  Si usted está visitando alguno de nuestros micrositios o está consultando una versión impresa, favor de revisar siempre el Aviso de Privacidad de nuestro portal principal www.herramientasinformaticas.com.  Al proporcionarnos, por cualquier medio sus datos personales, usted Titular, expresamente reconoce y acepta el presente AVISO DE PRIVACIDAD, por lo que dicho consentimiento nos otorga la facultad para que procedamos con el tratamiento de los mismos de la forma en que se señala en el presente AVISO DE PRIVACIDAD y con estricto apego a la legislación, sin perjuicio de los derechos que le confiere la misma.
                </li>
            </ul>
            </>:
            <>
            </>
        }</>:
        <>
            <h2>TÉRMINOS DE USO</h2>
            <p>Estos Términos y Condiciones de uso rigen la utilización del sitio "http://www.herramientasinformaticas.com", en adelante "SITIO HERRAMIENTAS INFORMÁTICAS", y su acceso a éste y todas sus páginas y secciones; por lo que constituyen un "Acuerdo" entre usted como usuario del sitio, en adelante el "USUARIO" y HERRAMIENTAS INFORMÁTICAS, en adelante "HERRAMIENTAS INFORMÁTICAS", como empresa mexicana con sede principal en Independencia #900, Barrio Nuevo, Orizaba, Veracruz, México y usted como usuario, en adelante "USUARIO". Al entrar a este sitio, usted acepta estos "Términos y Condiciones", por lo que se le pide que lea detenidamente antes de iniciar su visita al sitio o a cualquiera de sus secciones o páginas vinculadas.</p>

    <h3>I. Responsabilidades del USUARIO:</h3>

    <ol>
        <li>El USUARIO reconoce y acepta que HERRAMIENTAS INFORMÁTICAS no es responsable y lo exime de toda responsabilidad por la pérdida, daño o riesgo que resulte como consecuencia directa o indirecta del uso del SITIO HERRAMIENTAS INFORMÁTICAS;</li>
        <li>El USUARIO reconoce y acepta que su acceso y uso está sujeto a los términos de este Acuerdo y sus modificaciones futuras que se realicen por medio de su publicación en el propio SITIO HERRAMIENTAS INFORMÁTICAS.</li>
        <li>El USUARIO sólo utilizará los materiales contenidos en el SITIO HERRAMIENTAS INFORMÁTICAS para fines personales e informativos no comerciales, y que no modificará ninguno de los materiales contenidos en el sitio de HERRAMIENTAS INFORMÁTICAS.</li>
        <li>El usuario acepta cumplir todas las disposiciones legales locales, estatales, nacionales e internacionales y es el único responsable por todas las acciones u omisiones que ocurran como consecuencia de su uso o acceso al SITIO HERRAMIENTAS INFORMÁTICAS,</li>
        <li>Además el USUARIO tendrá las siguientes obligaciones:
            <ul>
                <li>No usar el SITIO WEB DE HERRAMIENTAS INFORMÁTICAS en relación con cartas en cadena, correo electrónico publicitario, correo electrónico de publicidad indeseada, ni ningún duplicado ni mensajes no solicitados (comerciales o de otra índole);</li>
                <li>No recopilar, compilar ni diseminar información acerca de terceros, incluyendo las direcciones de correo electrónico, sin consentimiento;</li>
                <li>No crear una identidad falsa o una dirección de correo electrónico o encabezamiento falsificado, ni intentar de alguna manera engañar a otras personas sobre la identidad del remitente o el origen de un mensaje;</li>
                <li>No transmitir a través del SITIO HERRAMIENTAS INFORMÁTICAS, materiales ilegales, hostigantes, difamatorios, abusivos, amenazantes, perjudiciales, vulgares, obscenos, ofensivos o de índole censurable;</li>
                <li>No transmitir algún material que pudiera infringir los derechos de propiedad intelectual u otros derechos de terceros, incluyendo y sin limitaciones, marcas comerciales, secretos comerciales o derechos de autor;</li>
                <li>No transmitir algún material que contenga virus, caballos de Troya, gusanos, bombas de tiempo, programas de cancelación, ni ningún otro programa perjudicial o nocivo;</li>
                <li>No obtener o intentar obtener acceso no autorizado al SITIO HERRAMIENTAS INFORMÁTICAS, u otras cuentas, sistemas de computadora o redes conectadas con el SITIO HERRAMIENTAS INFORMÁTICAS por medio de la búsqueda ilegal de contraseñas ni por ningún otro medio;</li>
                <li>No interferir en el uso y disfrute del SITIO HERRAMIENTAS INFORMÁTICAS por parte de otro miembro.</li>
            </ul>
        </li>
        <li>Al publicar o enviar contenido al SITIO HERRAMIENTAS INFORMÁTICAS, usted le otorga automáticamente a HERRAMIENTAS INFORMÁTICAS el derecho a usar, reproducir, presentar, exhibir, adaptar, modificar, distribuir o promover el contenido de cualquier forma y para cualquier fin. Asimismo, usted acepta y garantiza que tiene los derechos de propiedad necesarios para dicho contenido o control sobre dicho contenido y que la publicación y uso del contenido de parte de HERRAMIENTAS INFORMÁTICAS no infringe ni viola los derechos de ningún tercero. Usted acepta y confirma que es responsable por todo el contenido que publique o envíe.</li>
    </ol>

    <h3>II. Responsabilidades de HERRAMIENTAS INFORMÁTICAS:</h3>

    <ol>
        <li>Es la política de HERRAMIENTAS INFORMÁTICAS respetar la privacidad de los usuarios del Sitio de HERRAMIENTAS INFORMÁTICAS, por lo que se somete a las políticas de privacidad que se presenta en la sección de "Políticas de Privacidad" y del "Aviso de Privacidad".</li>
        <li>Todas las marcas comerciales, marcas de servicio y/o nombres comerciales que aparecen en el SITIO HERRAMIENTAS INFORMÁTICAS que no son marcas de HERRAMIENTAS INFORMÁTICAS, son marcas comerciales de sus respectivos propietarios. Las menciones que se hagan de cualquier nombre, marca, producto, o servicios de terceros no constituyen ni implica un patrocinio, promoción o recomendación para dichos terceros o para sus productos o servicios.</li>
        <li>HERRAMIENTAS INFORMÁTICAS puede, sin previo aviso, suspender, desconectar, denegar o restringir su acceso al Sitio HERRAMIENTAS INFORMÁTICAS en los siguientes casos:
            <ul>
                <li>Durante un fallo técnico del SITIO HERRAMIENTAS INFORMÁTICAS, o durante la modificación o mantenimiento del mismo;</li>
                <li>Si el USUARIO usa el SITIO HERRAMIENTAS INFORMÁTICAS de manera que viole cualquier normatividad vigente o las presentes reglas de uso;</li>
                <li>Si USUARIO hace algo o deja de hacer algo que en la opinión de HERRAMIENTAS INFORMÁTICAS pudiera poner en peligro el funcionamiento o la integridad del SITIO HERRAMIENTAS INFORMÁTICAS.</li>
            </ul>
        </li>
    </ol>

    <h3>III. Derechos de Propiedad del Contenido</h3>

    <p>El usuario acepta que el contenido, que incluye, sin limitarse a, texto, datos, software, música, sonido, fotografías, videos, gráficos u otros materiales a los que usted tiene acceso al usar el SITIO HERRAMIENTAS INFORMÁTICAS. También acepta que todos los recursos del SITIO HERRAMIENTAS INFORMÁTICAS están protegidos por la Ley Federal de Derechos de Autor; La Ley de Propiedad Intelectual; por lo que acepta respetar todos los derechos inherentes a dichos ordenamientos en favor de los autores y de HERRAMIENTAS INFORMÁTICAS. Usted acepta que toda información provista por HERRAMIENTAS INFORMÁTICAS o a través de HERRAMIENTAS INFORMÁTICAS es para fines informativos únicamente y no tiene el fin de ofrecer asesoría profesional.</p>

    <h3>IV. Exclusión de Garantías y Limitación de Responsabilidad Civil</h3>

    <p>Es posible que el Sitio de HERRAMIENTAS INFORMÁTICAS contenga enlaces a sitios, recursos y patrocinadores de terceros. La existencia de enlaces a y desde el Sitio HERRAMIENTAS INFORMÁTICAS a sitios de terceros no constituye un patrocinio de parte de HERRAMIENTAS INFORMÁTICAS de ningún sitio, recursos, patrocinadores o contenido de terceros, y HERRAMIENTAS INFORMÁTICAS no acepta ninguna responsabilidad, directa o indirectamente, por ninguno de esos sitios, recursos, patrocinadores o contenido. HERRAMIENTAS INFORMÁTICAS no representa ni aprueba la precisión o confiabilidad de ningún consejo, opinión, declaración ni ninguna otra información presentada o distribuida a través del SITIO HERRAMIENTAS INFORMÁTICAS. Usted acepta que al confiar en alguna de dichas opiniones, consejos, declaraciones o información, lo hace a riesgo propio. El sitio de HERRAMIENTAS INFORMÁTICAS, incluyendo todo el contenido, software, funciones, materiales e información que en él se presente o al que se obtenga acceso a través del mismo se suministra "tal cual". HERRAMIENTAS INFORMÁTICAS no se responsabiliza ni ofrece garantías de ningún tipo con respecto a:
    </p>

    <ul>
        <li>El contenido del SITIO HERRAMIENTAS INFORMÁTICAS;</li>
        <li>Los materiales, la información o las funciones a las cuales se obtenga acceso por medio del SITIO HERRAMIENTAS INFORMÁTICAS.</li>
        <li>Todo producto o servicio de terceros o enlaces a terceros.</li>
        <li>Toda violación a la seguridad asociada con la transmisión de información sensible a través del SITIO HERRAMIENTAS INFORMÁTICAS o algún sitio enlazado.</li>
    </ul>

    <p>HERRAMIENTAS INFORMÁTICAS no garantiza que las funciones contenidas en el SITIO HERRAMIENTAS INFORMÁTICAS o algún material o contenido del mismo estarán libres de interrupciones o errores, que todos los defectos se corregirán, o que el SITIO HERRAMIENTAS INFORMÁTICAS o el servidor en el cual funciona está libre de virus u otros componentes perjudiciales. HERRAMIENTAS INFORMÁTICAS no acepta ninguna responsabilidad por ninguna pérdida o daño (incluidos y sin limitaciones, pérdidas o daños indirectos, especiales, o directos) que resulte del uso del SITIO HERRAMIENTAS INFORMÁTICAS independientemente de si éstos ocurren a consecuencia de algún acto negligente u omisión. Si alguna ley, norma o decisión implica que algún término o garantía no puede excluirse, la única responsabilidad de HERRAMIENTAS INFORMÁTICAS será, en el caso de bienes o servicios, la de volver a suministrar los bienes o servicios, o pagar por el costo de suministrar los bienes o servicios de nueva cuenta. HERRAMIENTAS INFORMÁTICAS podría ofrecerle herramientas para efectuar transacciones o interactuar de alguna otra manera con terceros, incluyendo individuos. Los USUARIOS deben tomar las medidas apropiadas al suministrar información a dichos terceros o en interacciones de otra índole con tales terceros. Todos los contactos o las interacciones entre USUARIO y terceros se hacen bajo el riesgo de este, y HERRAMIENTAS INFORMÁTICAS no tiene ninguna responsabilidad en tales contactos o interacciones.</p>

    <h3>V. Legislación y Jurisdicción Aplicables</h3>

    <p>La publicación del sitio web, y la información que se contiene y pública, así como el acceso y uso que realice, se rigen por las leyes de México, por lo que HERRAMIENTAS INFORMÁTICAS y los USUARIOS, por el solo hecho de realizar dichas acciones, se someten a la leyes mexicanas y a la jurisdicción de los tribunales locales del domicilio de HERRAMIENTAS INFORMÁTICAS, renunciado a cualquier otra que les corresponda por cualquier otra causa.</p>

    <h3>VI. Modificación y vigencia.</h3>

    <p>HERRAMIENTAS INFORMÁTICAS se reserva el derecho de actualizar o revisar estos términos y condiciones sin darle previo aviso; por lo que el USUARIO deberá revisar estos términos y condiciones periódicamente para enterarse de los cambios. El uso continuo de este sitio después de la publicación de algún cambio en estos términos y condiciones indica que usted acepta dichos cambios.</p>

    <p>* Las ofertas de HERRAMIENTAS INFORMÁTICAS publicadas en el sitio web pueden cambiar sin previo aviso.</p>
        </>
       }
    </div>
  )
}
